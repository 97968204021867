import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Divider, IconButton, Stack } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import Layout from '../../common/components/Layout/Layout'
import HtmlTooltip from '../../common/components/HtmlTooltip/HtmlTooltip'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { api } from '../../api/api'
import { eval401Error, defaultCatch, _dispatch as dispatch, defaultSuccess } from '../../state/actions'

import {
  InfoOutlined as InfoOutlinedIcon,
  Edit as EditIcon,
  Add as AddIcon,
  DirectionsCarFilled as DirectionsCarFilledIcon
} from '@mui/icons-material'

import './Information.sass'
import VehicleModal from '../../common/components/VehicleModal/VehicleModal'
import { DEFAULT_VEHICLE } from '../../variables'
import { setLoading } from '../../state/actions'


function Information(props) {
  const {
    profile = {},
    setLoading,
    defaultSuccess,
    defaultCatch
  } = props

  const {
    first_name,
    last_name,
    email,
    card_number,
    tag_number,
    beacon,
    preferentialAccess,
    lng
  } = profile


  const organization = profile.company.name
  const groups = profile.groups.map(g => g.name).join(', ')
  const team = profile.team.map(g => g.name).join(', ')

  const theme = useTheme()
  const navigate = useNavigate()
  const isLarge = useMediaQuery(theme.breakpoints.up('md'))
  const { t } = useTranslation()

  const [openVehicle, setOpenVehicle] = useState(false)
  const [vehicles, setVehicles] = useState([])
  const [vehicle, setVehicle] = useState(DEFAULT_VEHICLE)

  const openModalVehicle = vehicle => {
    setVehicle({ ...vehicle })
    setOpenVehicle(true)
  }

  const fetchVehicles = useCallback(() => {
    setLoading(true)
    api.get('/vehicles')
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success) return
        setVehicles(data.vehicles)
      })
      .catch(error => defaultCatch(error, navigate, fetchVehicles))
  }, [defaultCatch, defaultSuccess, navigate, setLoading])

  const oncloseModal = () => {
    setOpenVehicle(false)
    fetchVehicles()
  }

  useEffect(() => {
    fetchVehicles()
  }, [fetchVehicles])

  return (
    <Layout title={t('my information')}>
      <div className="Information general-scroll">
        <div className="information-inner">
          <div className='item-wrapper'>
            <h2>{t('name')}</h2>
            <h3>{first_name}</h3>
          </div>
          <Divider />
          <div className='item-wrapper'>
            <h2>{t('last name')}</h2>
            <h3>{last_name}</h3>
          </div>
          <Divider />
          <div className='item-wrapper'>
            <h2>{t('email')}</h2>
            <h3>{email}</h3>
          </div>
          <Divider />
          <div className='item-wrapper'>
            <h2>{t('vehicles')}</h2>
            <br />
            <Stack direction="column" spacing={2} divider={<Divider flexItem />}>
              {vehicles.map((vehicle, index) => {
                return (
                  <Stack key={index} direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                    <Stack spacing={2} direction="row" alignItems="center">
                      <DirectionsCarFilledIcon />
                      <Stack spacing={0} alignItems="start">
                        <span>{t('vehicle')}</span>
                        <h2>{vehicle.name}</h2>
                        <h3>{vehicle.license_plate}</h3>
                      </Stack>
                    </Stack>
                    <IconButton
                      className="item-icon"
                      size="large"
                      edge="start"
                      aria-label="edit"
                      onClick={() => openModalVehicle(vehicle)}
                    >
                      <EditIcon />
                    </IconButton>
                  </Stack>
                )
              })}
              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={() => openModalVehicle(DEFAULT_VEHICLE)}>
                {t('add vehicle')}
              </Button>
            </Stack>
          </div>
          {card_number && <>
            <Divider />
            <div className='item-wrapper'>
              <h2>{t('card')}</h2>
              <h3>{card_number}</h3>
            </div>
          </>
          }
          {tag_number && <>
            <Divider />
            <div className='item-wrapper'>
              <h2>{t('tag')}</h2>
              <h3>{tag_number}</h3>
            </div>
          </>
          }
          <Divider />
          <div className='item-wrapper'>
            <h2>{t('beacon')}</h2>
            <h3>{beacon}</h3>
          </div>
          <Divider />
          <div className='item-wrapper'>
            <h2>{t('organization')}</h2>
            <h3>{organization}</h3>
          </div>
          <Divider />
          <div className='item-wrapper'>
            <h2>{t('team')}</h2>
            <h3>{team}</h3>
          </div>
          <Divider />
          <div className='item-wrapper'>
            <h2>{t('parking group')}</h2>
            <h3>{groups}</h3>
          </div>
          <Divider />
          <div className='item-wrapper'>
            <HtmlTooltip
              arrow
              placement={isLarge ? "right" : "left"}
              title={t('preferential access')}
              message={t('condition of preferential access due to disability, pregnancy or special condition')}>
              <IconButton
                className="item-icon"
                size="large"
                edge="start"
                aria-label="info"
              >
                <InfoOutlinedIcon />
              </IconButton>
            </HtmlTooltip>
            <h2>{t('preferential access')}</h2>
            <h3>{t(preferentialAccess.toString())}</h3>
          </div>
          <Divider />
          <div className='item-wrapper'>
            <h2>{t('language')}</h2>
            <h3>{lng}</h3>
          </div>
        </div>
      </div>
      <VehicleModal
        open={openVehicle}
        setOpen={setOpenVehicle}
        oncloseModal={oncloseModal}
        setVehicle={setVehicle}
        vehicle={vehicle}
      />
    </Layout>
  );
}

const mapStateToProps = state => {
  return {
    profile: state.profile
  }
}

const mapDispatchToProps = {
  eval401Error,
  defaultSuccess,
  defaultCatch,
  setLoading,
  dispatch
}

export default connect(mapStateToProps, mapDispatchToProps)(Information)
