import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react'
import { DateTime } from "luxon"
import './Reservations.sass'
import { connect } from 'react-redux'
import { requestQueue } from '../../common/utilities/RequestQueue'

import {
  Tab,
  Tabs,
  Stack,
  Select,
  Hidden,
  Button,
  Dialog,
  Divider,
  useTheme,
  MenuItem,
  TextField,
  IconButton,
  DialogContent,
  useMediaQuery,
  Box,
  Skeleton,
  Alert,
  FormControl,
  Chip
} from '@mui/material'

import {
  Event as EventIcon,
  LocationCity as LocationCityIcon,
  AirlineSeatReclineNormal as AirlineSeatReclineNormalIcon,
  WatchLater as WatchLaterIcon,
  CheckCircle as CheckCircleIcon,
  Error as ErrorIcon,
  Add,
  EmojiTransportation,
  DirectionsCar,
  WatchLater,
  Place,
  Notes,
  Close as CloseIcon,
  InfoOutlined as InfoOutlinedIcon,
  FiberManualRecord as FiberManualRecordIcon,
  Groups as GroupsIcon,
  Restaurant as RestaurantIcon,
  Place as PlaceIcon,
  ListAlt as ListAltIcon,
  AlternateEmail,
  Link as LinkIcon,
} from '@mui/icons-material'

import Layout from '../../common/components/Layout/Layout'
import CardWrapper from '../../common/components/CardWrapper/CardWrapper'
import StatusChip from '../../common/components/StatusChip/StatusChip'
import Filters from '../../common/components/Filters/Filters'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { api } from '../../api/api'
import { Types } from '../../state/actionTypes'

import {
  logout,
  setLoading,
  defaultSuccess,
  defaultCatch,
  _dispatch as dispatch,
} from '../../state/actions'

import SwipeableTimePicker from '../../common/components/SwipeableTimePicker/SwipeableTimePicker'

import {
  Arrow,
  Download
} from '../../common/components/Svgs'

import {
  isAllDay,
  formatTime,
  formatDateToJS,
  formatDateToISO,
  formatTimeFromJS,
  formatDateISOShort,
  formatDateToTimeISO,
  formatDateToISOShort,
  getFormateRangeSessionTime,
  formatDateISOtoAMPM,
  formatDateMedMed,
  formatDateMed,
  getDiffInMinutesFromNow,
  isToday
} from '../../common/utilities/FormatDate'


import {
  getParkingReasons,
  getWorkplaceReasons,
  cleanReservation,
  cleanAssignations,
  cleanCafeteriaOrders,
  getPlace,
  getPosition,
  isDesk,
  isParking,
  parseMessageHtml,
  stopEvent,
  isReservation,
  isWorkplace,
  isCafeteriaOrder,
  getCurrentLocation,
  isNear,
  evalSetNotes,
  downloadTicketAsImage,
  getWorkplaceDefaultEntryTime,
  getWorkplaceDefaultExitTime
} from '../../common/utilities/utilities'

import {
  CENTER as DEFAULT_CENTER,
  PARKING_RESERVATION,
  DESK_RESERVATION,
  ROOM_RESERVATION,
  GOOGLE_MAP_URL,
  EIGHT_AM,
  FIVE_PM,
  WARNING,
  INFO,
  PARKING_ASSIGNATION,
  DESK_ASSIGNATION,
  CAFETERIA_RESERVATION,
  OK,
  REJECTED,
  ABSENCE,
  ENABLED_PR,
  ENABLED_PD
} from '../../variables'

import FloorMap from '../../common/components/FloorMap/FloorMap'
import ModalNotification from '../../common/components/ModalNotification/ModalNotification'
import CustomWorkplaceIcons from '../../common/utilities/CustomWorkplaceIcons'
import ScheduleSelector from '../../common/components/ScheduleSelector/ScheduleSelector'

const today = new Date()
const iconSx = { color: '#8C8CA1', fontSize: 15 }
const initialItem = {
  id: 0,
  absence: false,
  arrived: false,
  entry_time: today,
  parking_date: "",
  parking_lot_id: 0,
  parking_space: {},
  parking_type: "",
  reason: "0",
  reason_id: 4,
  status: "APPROVED",
  type: "ParkingReservation",
  user_id: 45,
  vehicle: {}
}

function Reservations(props) {
  const {
    dispatch,
    logout,
    sites,
    setLoading,
    defaultSuccess,
    defaultCatch,
    enabled_room,
    enabled_parking,
    enabled_workplace,
    reservationsStatic,
    workplaceEnabledSymptomAssessment,
    askForRoomReservationNotes,
    askForParkingReservationReason,
    enabledMultiplesParkingReservations,
    askForParkingReservationEntryTime,
    askForParkingReservationVehicle,
    askForDeskReservationReason,
    askForDeskReservationEntryTime,
    enabledPrivacyDataApproval,
    hasAcceptedPrivacyPolicy,
    userId,
    enabled_cafeteria,
    cafeteriaMinTimeForCancelOrder,
    workplaceCustomLabel,
    workplaceCustomIconIndex,
    enabledWorkplaceCustomIcon,
    workplaceDefaultEntryTime,
    workplaceDefaultExitTime,
    enabledWorkplaceRoomReservationsAttendees,
    enabledPR,
    enabledPD,
    reservationsAndAssignations
  } = props

  const navigate = useNavigate()
  const { t } = useTranslation()
  const [tab, setTab] = useState('upcoming')
  const [open, setOpen] = useState(false)
  const [remove, setRemove] = useState(false)
  const [item, setItem] = useState(initialItem)
  const [typeFilter, setTypeFilter] = useState(-1)
  const [statusFilter, setStatusFilter] = useState(-1)
  const [dateFilter, setDateFilter] = useState([null, null])

  const [openScheduleSelector, setOpenScheduleSelector] = useState(false)
  const [openTimePicker, setOpenTimePicker] = useState(false)
  const [timePickerValue, setTimePickerValue] = useState(EIGHT_AM)
  const [exitTimePickerValue, setExitTimePickerValue] = useState(FIVE_PM)
  const [entry_time, setEntryTime] = useState(EIGHT_AM)
  const [exit_time, setExitTime] = useState(FIVE_PM)
  const [allTime, setAllTime] = useState(false)
  const [allTimeValue, setAllTimeValue] = useState(false)
  const [markers, setMarkers] = useState([])
  const [floorImage, setFloorImage] = useState({})
  const [openMarkArrivalModal, setOpenMarkArrivalModal] = useState(false)
  const [openPrivacyNotice, setOpenPrivacyNotice] = useState(false)
  const [titlePrivacyNotice, setTitlePrivacyNotice] = useState(t("Notice of Privacy"))
  const [paragraphPrivacyNotice, setparagraphPrivacyNotice] = useState(t("We respect your privacy. You can accept or reject the use of your data for informational purposes."))
  const [typePrivacyNotice, setTypePrivacyNotice] = useState(INFO)
  const [messagePrivacyNotice, setMessagePrivacyNotice] = useState('')
  const [siteGeo, setSiteGeo] = useState(false)

  const [reservations, setReservations] = useState([])
  const [deskAssignations, setDeskAssignations] = useState([])
  const [parkingAssignations, setParkingAssignations] = useState([])
  const [parkingDeskReservations, setParkingDeskReservations] = useState([])
  const [roomReservations, setRoomReservations] = useState([])
  const [cafeteriaOrders, setCafeteriaOrders] = useState([])
  const [orderQR, setOrderQR] = useState('')
  const [roomAttendeeEmail, setRoomAttendeeEmail] = useState('')
  const [emailFormatError, setEmailFormatError] = useState(false)
  const [emailAlreadyInList, setEmailAlreadyInList] = useState(false)

  const theme = useTheme()
  const isLarge = useMediaQuery(theme.breakpoints.up('md'))
  const cardWrapperRef = useRef(null)
  const parkingReasons = useMemo(() => getParkingReasons(t), [t])
  const workplaceReasons = useMemo(() => getWorkplaceReasons(t), [t])


  const setReservationsStatic = payload => {
    dispatch({
      type: Types.SET_RESERVATIONS_AND_ASSIGNATIONS,
      payload
    })
  }

  const setTotalParkingReservation = useCallback(total => {
    dispatch({
      type: Types.SET_PARKING_TOTAL_RESERVATIONS,
      payload: total
    })
  }, [dispatch])

  const setTotalDeskReservation = useCallback(total => {
    dispatch({
      type: Types.SET_DESK_TOTAL_RESERVATIONS,
      payload: total
    })
  }, [dispatch])

  const setTotalRoomReservation = useCallback(total => {
    dispatch({
      type: Types.SET_ROOM_TOTAL_RESERVATIONS,
      payload: total
    })
  }, [dispatch])

  const setTotalCafeteriaOrders = useCallback(total => {
    dispatch({
      type: Types.SET_CAFETERIA_TOTAL_ORDERS,
      payload: total
    })
  }, [dispatch])

  const filterReservation = useCallback((reservations, date = dateFilter) => {

    if (typeFilter !== -1) {
      reservations = reservations.filter(
        r => r.type === typeFilter
      )
    }

    if (statusFilter !== -1) {
      reservations = reservations.filter(
        r => r.status === statusFilter
      )
    }

    const [start, end] = date

    if (start && end) {
      const _start = formatDateToISOShort(start)
      const _end = formatDateToISOShort(end)

      reservations = reservations.filter(
        r =>
          (r.reservation_date || r.order_date) >= _start &&
          (r.reservation_date || r.order_date) <= _end
      )
    }

    reservations = reservations.sort(
      (a, b) => (a.reservation_date || a.order_date) < (b.reservation_date || b.order_date) ? -1 : 1
    )

    if (cardWrapperRef.current) cardWrapperRef.current.scrollTo(0, 0)
    return reservations
  }, [dateFilter, statusFilter, typeFilter])

  const setTotalReservations = useCallback(reservations => {
    setTotalParkingReservation(reservations.filter(r => r.type === PARKING_RESERVATION && r.status !== ABSENCE).length)
    setTotalDeskReservation(reservations.filter(r => r.type === DESK_RESERVATION).length)
    setTotalRoomReservation(reservations.filter(r => r.type === ROOM_RESERVATION).length)
    setTotalCafeteriaOrders(reservations.filter(r => r.type === CAFETERIA_RESERVATION).length)
  }, [setTotalParkingReservation, setTotalRoomReservation, setTotalDeskReservation, setTotalCafeteriaOrders])

  const fetchRoomReservations = useCallback(() => {
    setLoading(true)
    api.get('/workplace/room_reservations')
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success) return
        const roomReservations = cleanReservation(data.room_reservations, { type: ROOM_RESERVATION })
        setRoomReservations(roomReservations)
      })
      .catch(error => defaultCatch(error, navigate, fetchRoomReservations))
  }, [defaultCatch, defaultSuccess, navigate, setLoading])

  const fetchReservations = useCallback(() => {
    setLoading(true)
    api.get('/reservations')
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success) return
        const reservations = cleanReservation(data.reservations)
        setParkingDeskReservations(reservations)
        if (enabled_workplace && enabled_room) fetchRoomReservations()
      })
      .catch(error => defaultCatch(error, navigate, fetchReservations))
      .finally(() => setLoading(false));
  }, [setLoading, defaultSuccess, enabled_workplace, enabled_room, fetchRoomReservations, defaultCatch, navigate])

  const fetchCafeteriaOrders = useCallback(() => {
    setLoading(true)
    api.get('/cafeteria/orders')
      .then(defaultSuccess)
      .then(({ data = {} }) => {
        if (!data.success) return
        const params = { type: CAFETERIA_RESERVATION }
        const orders = cleanCafeteriaOrders(data.orders || {}, params)
        setCafeteriaOrders(orders)
        fetchReservations()
      })
      .catch(error => defaultCatch(error, navigate, fetchCafeteriaOrders))
  }, [setLoading, defaultSuccess, fetchReservations, defaultCatch, navigate])

  const fetchDeskAssignations = useCallback(() => {
    setLoading(true)
    api.get('/workplace/desk_assignations')
      .then(defaultSuccess)
      .then(({ data = {} }) => {
        if (!data.success) return
        const params = { type: DESK_ASSIGNATION }
        const assignations = cleanAssignations(data.desk_assignation || {}, params)
        setDeskAssignations(assignations)
        if (enabled_cafeteria) fetchCafeteriaOrders()
        else fetchReservations()
      })
      .catch(error => defaultCatch(error, navigate, fetchDeskAssignations))
  }, [defaultCatch, defaultSuccess, enabled_cafeteria, fetchCafeteriaOrders, fetchReservations, navigate, setLoading])


  const fetchParkingAssignations = useCallback(() => {
    setLoading(true)
    api.get('/parking_assignations')
      .then(defaultSuccess)
      .then(({ data = {} }) => {
        if (!data.success) return
        const params = { type: PARKING_ASSIGNATION }
        const assignations = cleanAssignations(data.parking_assignation || {}, params)
        setParkingAssignations(assignations)
        if (enabled_workplace) fetchDeskAssignations()
        else if (enabled_cafeteria) fetchCafeteriaOrders()
        else fetchReservations()
      })
      .catch(error => defaultCatch(error, navigate, fetchParkingAssignations))
  }, [setLoading, defaultSuccess, enabled_workplace, fetchDeskAssignations, enabled_cafeteria, fetchCafeteriaOrders, fetchReservations, defaultCatch, navigate])

  const fetchData = useCallback(() => {
    if (enabled_parking) fetchParkingAssignations()
    else if (enabled_workplace) fetchDeskAssignations()
    else if (enabled_cafeteria) fetchCafeteriaOrders()
    else fetchReservations()
  }, [enabled_parking, enabled_workplace, enabled_cafeteria, fetchDeskAssignations, fetchParkingAssignations, fetchReservations, fetchCafeteriaOrders])

  const fetchQRImage = useCallback((item) => {
    const qr = item?.qr?.replace(/^\/api/, '');
    if (!qr) {
      console.error('QR code is not defined');
      return;
    }

    setLoading(true)
    requestQueue.addRequest({
      request: () =>
        api.get(qr, { responseType: "blob" })
          .then(defaultSuccess)
          .then(({ data }) => setOrderQR(URL.createObjectURL(data))),
      resolve: () => setLoading(false),
      reject: (error) => defaultCatch(error, navigate, () => fetchQRImage(item)),
    });
  }, [defaultCatch, defaultSuccess, navigate, setLoading]);

  const loadPrivacyMessage = useCallback(() => {
    setLoading(true)
    api.get('/privacy/users')
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success) return
        setMessagePrivacyNotice(parseMessageHtml(data.privacy_message))
        fetchData()
      })
      .catch(error => defaultCatch(error, navigate, loadPrivacyMessage))
  }, [defaultCatch, defaultSuccess, fetchData, navigate, setLoading])

  const onFilter = useCallback((date = dateFilter) => {
    setReservations(
      filterReservation(
        reservationsStatic,
        date
      )
    )
  }, [dateFilter, filterReservation, reservationsStatic])

  const isAnyModalOpen = useMemo(() => {
    return (
      open ||
      remove ||
      openScheduleSelector ||
      openTimePicker ||
      openMarkArrivalModal ||
      openPrivacyNotice
    );
  }, [
    open,
    remove,
    openScheduleSelector,
    openTimePicker,
    openMarkArrivalModal,
    openPrivacyNotice,
  ]);

  useEffect(() => {
    if (enabledPrivacyDataApproval && !hasAcceptedPrivacyPolicy) {
      loadPrivacyMessage()
      setOpenPrivacyNotice(true)
    } else fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const reservations = [
      ...parkingDeskReservations,
      ...roomReservations,
      ...parkingAssignations,
      ...deskAssignations,
      ...cafeteriaOrders
    ]
    setTotalReservations(reservations)
    setReservationsStatic(reservations)
    setReservations(filterReservation(reservations))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deskAssignations, parkingAssignations, parkingDeskReservations, roomReservations, cafeteriaOrders])

  useEffect(() => {
    onFilter()
  }, [onFilter, typeFilter])

  useEffect(() => {
    if (!enabledPR) return;
    const intervalId = setInterval(() => {
      if (!isAnyModalOpen) {
        fetchRoomReservations();
      }
    }, 60000);

    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setReason = useCallback(value => {
    const _item = { ...item }
    _item.reason = value
    setItem(_item)
  }, [item])

  const setNotes = useCallback(value => {
    const _item = { ...item }
    _item.notes = value
    setItem(_item)
  }, [item])

  const errorUpdateMessage = useCallback(message => {
    dispatch({
      type: Types.SET_SNACKBAR_DATA,
      payload: {
        open: true,
        message: message || t('something went wrong'),
        autoHideDuration: null,
        severity: 'error'
      }
    })
  }, [dispatch, t]);

  const onUpdateWorkplaceReservation = useCallback(() => {
    const data = {};
    const type =
      item.type === DESK_RESERVATION ? 'desk_reservation' : 'room_reservation';

    let _entry_time = formatDateToISO(entry_time);
    let _exit_time = formatDateToISO(exit_time);

    data[type] = {
      reason: item.reason,
      entry_time: _entry_time,
      exit_time: _exit_time,
      notes: item.notes,
    };

    if (type === 'room_reservation') {
      data[type].attendees = item.attendees || [];
    }

    api
      .put(`/workplace/${type}/${item.id}`, data)
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success) {
          errorUpdateMessage(data.message);
          return;
        };
        item.type === DESK_RESERVATION
          ? fetchReservations()
          : fetchRoomReservations();
      })
      .catch((error) =>
        defaultCatch(error, navigate, onUpdateWorkplaceReservation)
      );
  }, [
    errorUpdateMessage,
    defaultCatch,
    defaultSuccess,
    entry_time,
    exit_time,
    fetchReservations,
    fetchRoomReservations,
    item,
    navigate,
  ]);


  const onUpdateParkingReservation = useCallback(() => {
    let _entry_time = formatDateToISO(entry_time)
    let _exit_time = formatDateToISO(exit_time)

    const data = {
      reason: item.reason,
      entry_time: _entry_time,
      exit_time: _exit_time
    }

    api.put(`/parking_reservations/${item.id}`, data)
      .then(defaultSuccess)
      .then(({ data = {} }) => {
        if (!data.success) {
          errorUpdateMessage(data.message)
          return
        }
        fetchReservations()
      })
      .catch(error => defaultCatch(error, navigate, onUpdateParkingReservation))

  }, [entry_time, exit_time, item, defaultSuccess, fetchReservations, errorUpdateMessage, defaultCatch, navigate])

  const onSaveReservation = useCallback(() => {
    setOpen(false)
    setLoading(true)

    if (item.type === PARKING_RESERVATION)
      onUpdateParkingReservation()
    else onUpdateWorkplaceReservation()
  }, [item.type, onUpdateParkingReservation, onUpdateWorkplaceReservation, setLoading])


  const setFloorMapDesk = useCallback((data, item) => {
    const { desks } = data.floor_map

    if (item) {
      const marker = desks.find(m => m.id === item.desk.id)
      if (marker) {
        marker.selection = true
        setMarkers([marker])
      }
    }
  }, [])

  const setFloorMapRoom = useCallback((data, item) => {
    const { rooms } = data.floor_map
    if (item) {
      const marker = rooms.find(m => m.id === item.room.id)
      if (marker) {
        marker.selection = true
        setMarkers([marker])
      }
    }
  }, [])

  const fetchFloorsImage = useCallback((params) => {
    const { id, width, height } = params
    setLoading(true)
    api.get(`/workplace/floor_map/serve/${id}`, { responseType: "blob" })
      .then(defaultSuccess)
      .then(({ data }) => {
        const blob = URL.createObjectURL(data)
        setFloorImage({ url: blob, size: [width, height] })
      })
      .catch(error => defaultCatch(error, navigate, () => fetchFloorsImage(params)))
  }, [defaultCatch, defaultSuccess, navigate, setLoading])

  const fetchFloorMap = useCallback(item => {
    setLoading(true)
    api.get(`/workplace/floor_map/${item.floor_map.id}`)
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success) return
        if (item.type === DESK_RESERVATION) setFloorMapDesk(data, item)
        if (item.type === ROOM_RESERVATION) setFloorMapRoom(data, item)
        fetchFloorsImage(data.floor_map)
      })
      .catch(error => defaultCatch(error, navigate, () => fetchFloorMap(item)))
  }, [defaultCatch, defaultSuccess, fetchFloorsImage, navigate, setFloorMapDesk, setFloorMapRoom, setLoading])

  const getMarkArrivalParams = useCallback(() => {
    let url = ''
    let payload = { id: item.id }

    if (isParking(item)) {
      url = 'parking_sessions'
      payload.parking_session = {
        id: item.id,
        user_id: userId
      }
    }

    else if (isDesk(item)) {
      url = 'workplace/desk_session'
      payload.desk_session = {
        id: item.id
      }
    }

    else if (item.type === ROOM_RESERVATION) {
      url = 'workplace/room_session'
      payload.room_session = {
        id: item.id
      }
    }

    return { url, payload }

  }, [item, userId])

  const onMarkArrival = useCallback(async () => {
    if (!item.has_session && (siteGeo.latitude + siteGeo.longitude) !== 0) {
      const currentLocation = await getCurrentLocation()
      if (currentLocation.status !== OK) {
        dispatch({
          type: Types.SET_SNACKBAR_DATA,
          payload: {
            open: true,
            message: currentLocation.message,
            severity: 'error'
          }
        })
        return
      }

      if (!isNear(siteGeo, currentLocation)) {
        dispatch({
          type: Types.SET_SNACKBAR_DATA,
          payload: {
            open: true,
            message: t('You must be at your workplace to clock in.'),
            severity: 'error'
          }
        })
        return
      }
    }

    setOpenMarkArrivalModal(false)
    setLoading(true)

    const { url, payload } = getMarkArrivalParams()
    api.post(url, payload)
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success) return
        if (isReservation(item)) fetchReservations()
        else fetchData()
      })
      .catch(error => defaultCatch(error, navigate, onMarkArrival))

  }, [defaultCatch, defaultSuccess, dispatch, fetchData, fetchReservations, getMarkArrivalParams, item, navigate, setLoading, siteGeo, t])

  const openItem = useCallback(
    (item) => {
      setItem({
        ...item,
        attendees: item.attendees || []
      });
      setEntryTime(formatDateToJS(item.entry_time));
      setTimePickerValue(formatDateToJS(item.entry_time));
      setAllTime(isAllDay(item));
      setAllTimeValue(isAllDay(item));
      setExitTime(item.exit_time ? formatDateToJS(item.exit_time) : FIVE_PM);
      setExitTimePickerValue(
        item.exit_time ? formatDateToJS(item.exit_time) : FIVE_PM
      );
      if (isWorkplace(item)) fetchFloorMap(item);
      if (isCafeteriaOrder(item)) fetchQRImage(item);
      setOpen(true);
    },
    [fetchFloorMap, fetchQRImage]
  );

  const removeItem = item => {
    setItem(item)
    setEntryTime(formatDateToJS(item.entry_time))
    setAllTimeValue(isAllDay(item))
    setAllTime(isAllDay(item))
    setExitTime(item.exit_time ? formatDateToJS(item.exit_time) : FIVE_PM)
    setExitTimePickerValue(item.exit_time ? formatDateToJS(item.exit_time) : FIVE_PM)
    setRemove(true)
  }

  const onRemoveReservation = useCallback(() => {
    setRemove(true)
  }, [])

  const onCancelAssignation = useCallback(() => {
    setOpen(false)
    setRemove(false)
    setLoading(true)

    let url = 'parking_assignation/release'
    let data = { parking_date: item.reservation_date }

    api.post(url, data)
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success) return
        fetchParkingAssignations()
      })
      .catch(error => defaultCatch(error, navigate, onCancelAssignation))

  }, [defaultCatch, defaultSuccess, fetchParkingAssignations, item.reservation_date, navigate, setLoading])

  const onCancelReservation = useCallback(() => {
    setOpen(false)
    setRemove(false)
    setLoading(true)

    let url = 'parking_reservations'
    if (item.type === DESK_RESERVATION) url = 'workplace/desk_reservation'
    else if (item.type === ROOM_RESERVATION) url = 'workplace/room_reservation'
    else if (item.type === CAFETERIA_RESERVATION) url = 'cafeteria/order'

    api.delete(`/${url}/${item.id}`)
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success) return
        fetchParkingAssignations()
      })
      .catch(error => defaultCatch(error, navigate, onCancelReservation))
  }, [defaultCatch, defaultSuccess, fetchParkingAssignations, item.id, item.type, navigate, setLoading])


  const onCancel = useCallback(() => {
    if (item.type === PARKING_ASSIGNATION) onCancelAssignation()
    else onCancelReservation()
  }, [item, onCancelAssignation, onCancelReservation])

  const onSetType = type => {
    setTypeFilter(type)
  }

  const getMinTime = useCallback(() => {
    const parking_date = item.parking_date
    if (!parking_date) return
    const _parking_date = DateTime.fromISO(parking_date).toLocaleString(DateTime.DATE_SHORT)
    const _today = DateTime.fromJSDate(today).toLocaleString(DateTime.DATE_SHORT)
    if (_parking_date === _today) return today
  }, [item.parking_date])

  const disableUpdateReservation = useCallback(
    () => [REJECTED, ABSENCE].includes(item.status), [item.status]
  )

  const onChangeTime = useCallback(() => {
    if (disableUpdateReservation()) return
    setOpenTimePicker(true)
  }, [disableUpdateReservation])

  const handleCloseScheduleSelector = useCallback(() => {
    setOpenScheduleSelector(false)
    setTimePickerValue(entry_time)
    setExitTimePickerValue(exit_time)
  }, [entry_time, exit_time])

  const onChangeRoomTime = useCallback(() => {
    if (disableUpdateReservation()) return
    setOpenScheduleSelector(true)
  }, [disableUpdateReservation])

  const onContinueScheduleSelector = useCallback(() => {
    setOpenScheduleSelector(false)
    const _item = { ...item }

    setEntryTime(timePickerValue)
    setExitTime(exitTimePickerValue)

    _item.entry_time = formatDateToISO(timePickerValue)
    _item.exit_time = formatDateToISO(exitTimePickerValue)
    setItem(_item)
  }, [exitTimePickerValue, item, timePickerValue])

  const onChangeScheduleSelector = useCallback(params => {
    const { entry_time, exit_time } = params
    setTimePickerValue(entry_time)
    setExitTimePickerValue(exit_time)
  }, [])

  const getFormateTime = useCallback(() => {
    return formatTimeFromJS(entry_time)
  }, [entry_time])

  const getFormateRangeTime = useCallback(() => {
    if (allTime) return t('all day')
    if (entry_time && exit_time) {
      return `${formatTimeFromJS(entry_time)} - ${formatTimeFromJS(exit_time)}`
    } else if (item.entry_time && item.exit_time) {
      return `${formatDateISOtoAMPM(item.entry_time)} - ${formatDateISOtoAMPM(item.exit_time)}`
    } else {
      return `${EIGHT_AM} - ${FIVE_PM}`
    }
  }, [allTime, exit_time, entry_time, t, item.entry_time, item.exit_time])

  const onContinueTimePicker = useCallback(() => {
    setAllTime(allTimeValue)
    setEntryTime(timePickerValue)
    setExitTime(exitTimePickerValue)
    setOpenTimePicker(false)
  }, [allTimeValue, timePickerValue, exitTimePickerValue])

  const onCloseTimePicker = useCallback(() => {
    setOpenTimePicker(false)
    setAllTimeValue(allTime)
    setTimePickerValue(entry_time)
    setExitTimePickerValue(exit_time)
  }, [entry_time, exit_time, allTime])

  const getDeniedReason = useCallback(() => {
    if (item.status !== REJECTED || !item.reject_reason?.trim()) {
      return ''
    }

    return (
      <div className='denied-reason'>
        <label>{t('Reason for your reservation denied')}:</label>
        <div className='denied-reason-detail'>
          {item.reject_reason}
        </div>
      </div>
    )
  }, [item, t])

  const EntryTimeIsLaterEqualThanExitTime = useCallback(
    () => {
      if (!timePickerValue || !exitTimePickerValue) return false
      const entry = formatDateToTimeISO(timePickerValue)
      const exit = formatDateToTimeISO(exitTimePickerValue)
      return (entry >= exit)
    },
    [exitTimePickerValue, timePickerValue]
  )

  const disabledTimePickerWorplace = useCallback(
    () => EntryTimeIsLaterEqualThanExitTime(),
    [EntryTimeIsLaterEqualThanExitTime]
  )

  const renderTimePickerMessageWorkplace = useCallback(() => {
    if (EntryTimeIsLaterEqualThanExitTime()) {
      return (
        <p className="message danger">
          {t('Check out time must be greater than check in time')}
        </p>
      )
    }
    return <p className='message'></p>
  }, [EntryTimeIsLaterEqualThanExitTime, t])

  const getParkingTime = useCallback(item => {
    const time = enabledMultiplesParkingReservations ?
      getFormateRangeTime() :
      getFormateTime(item)

    return (
      <li className='time-wrapper'>
        <WatchLater sx={iconSx} /> {t('hour')}:
        <span>
          <TextField
            value={time}
            onClick={onChangeTime}
            variant="filled"
            size="small"
            className="time-picker-button"
            disabled={disableUpdateReservation()}
          />
        </span>
        {enabledMultiplesParkingReservations ?
          <SwipeableTimePicker
            type="range"
            allTime={false}
            open={openTimePicker}
            showAllTime={false}
            time={timePickerValue}
            exitTime={exitTimePickerValue}
            setTime={setTimePickerValue}
            setExitTime={setExitTimePickerValue}
            minTime={getMinTime()}
            setOpen={setOpenTimePicker}
            onContinue={onContinueTimePicker}
            onClose={onCloseTimePicker}
            isDisabled={disabledTimePickerWorplace()}
            renderMessage={renderTimePickerMessageWorkplace}
          /> :
          <SwipeableTimePicker
            allTime={false}
            showAllTime={false}
            minDate={today}
            minTime={getMinTime()}
            time={timePickerValue}
            open={openTimePicker}
            setTime={setTimePickerValue}
            setOpen={setOpenTimePicker}
            onContinue={onContinueTimePicker}
            onClose={onCloseTimePicker}
          />
        }

      </li>
    )
  }, [disableUpdateReservation, disabledTimePickerWorplace, enabledMultiplesParkingReservations, exitTimePickerValue, getFormateRangeTime, getFormateTime, getMinTime, onChangeTime, onCloseTimePicker, onContinueTimePicker, openTimePicker, renderTimePickerMessageWorkplace, t, timePickerValue])

  const getDetailDialogListParking = useCallback(() => {
    return (
      <ul className="detail-list">
        <li><EventIcon sx={iconSx} /> {t('date')}: <span>{formatDateISOShort(item.reservation_date)}</span> </li>
        <li><EmojiTransportation sx={iconSx} /> {t('parking')}: <span>{item?.parking_space?.parking_lot_name}</span></li>
        <li><Place sx={iconSx} /> {t('space')}:<span>{item?.parking_space?.parking_space_label}</span></li>
        {askForParkingReservationVehicle && <li><DirectionsCar sx={iconSx} width={10} /> {t('vehicle')}:<span>{item?.vehicle?.license_plate}</span></li>}
        {askForParkingReservationEntryTime && getParkingTime(item)}
        {askForParkingReservationReason && <li>
          <Notes sx={iconSx} /> {t('reason')}:
          <span>
            <Select
              className="select-button"
              variant="filled"
              size="small"
              value={item.reason}
              onChange={event => setReason(event.target.value)}
              disabled={disableUpdateReservation()}
            >
              {parkingReasons.map((r, index) =>
                <MenuItem
                  key={index}
                  value={r}>
                  {r}
                </MenuItem>
              )
              }
            </Select>
          </span>
        </li>}
      </ul>
    )
  }, [
    askForParkingReservationEntryTime,
    askForParkingReservationReason,
    askForParkingReservationVehicle,
    disableUpdateReservation,
    getParkingTime,
    parkingReasons,
    setReason,
    item,
    t
  ])

  const getCenterItem = useCallback(() => {
    if (!item) return DEFAULT_CENTER
    const m = markers.find(m => m.selection)
    const imageHeight = floorImage.size[1]
    return m ? getPosition(m, imageHeight) : DEFAULT_CENTER
  }, [floorImage.size, item, markers])

  const goHealtForm = useCallback(e => {
    stopEvent(e)
    navigate('/healt-form')
  }, [navigate])

  const getDetailDialogListWorkplace = useCallback(() => {
    return (
      <div className="detail-list-workplace">
        <ul className="detail-list">
          <li><EventIcon sx={iconSx} /> {t('date')}: <span>{formatDateISOShort(item.reservation_date)}</span> </li>
          <li><LocationCityIcon sx={iconSx} /> {t('place')}: <span>{getPlace(item)}</span></li>
          <li>{enabledWorkplaceCustomIcon && workplaceCustomIconIndex !== undefined ?
            <CustomWorkplaceIcons
              index={workplaceCustomIconIndex}
              sx={iconSx}
              color={"#8C8CA1"}
            /> :
            <AirlineSeatReclineNormalIcon sx={iconSx} />}
            {t('desk')}:<span>{item?.desk?.name}</span>
          </li>
          {askForDeskReservationEntryTime &&
            <li className='time-wrapper'>
              <WatchLater sx={iconSx} /> {t('hour')}:
              <span>
                <TextField
                  value={getFormateRangeTime()}
                  onClick={onChangeTime}
                  size="small"
                  variant="filled"
                  className="time-picker-button"
                  disabled={disableUpdateReservation()}
                />
              </span>
              <SwipeableTimePicker
                type="range"
                showAllTime={false}
                allTime={allTimeValue}
                time={timePickerValue}
                exitTime={exitTimePickerValue}
                open={openTimePicker}
                setAllTime={setAllTimeValue}
                setTime={setTimePickerValue}
                setExitTime={setExitTimePickerValue}
                minTime={getMinTime()}
                setOpen={setOpenTimePicker}
                onContinue={onContinueTimePicker}
                onClose={onCloseTimePicker}
              />
            </li>
          }
          {askForDeskReservationReason &&
            <li>
              <Notes sx={iconSx} /> {t('reason')}:
              <span>
                <Select
                  className="select-button"
                  size="small"
                  variant="filled"
                  value={item.reason}
                  onChange={event => setReason(event.target.value)}
                  disabled={disableUpdateReservation()}
                >
                  {workplaceReasons.map((r, index) =>
                    <MenuItem
                      key={index}
                      value={r}>
                      {r}
                    </MenuItem>
                  )}
                </Select>
              </span>
            </li>
          }
          {workplaceEnabledSymptomAssessment && (
            <li>
              {
                item.has_symptom_assessment ?
                  <><CheckCircleIcon sx={iconSx} className="SUCCESS" />{t("complete health check")} </> :
                  <Button className="go-healt-form" onClick={goHealtForm}><ErrorIcon sx={iconSx} className="DANGER" />{t("incomplete health check")} </Button>
              }
            </li>
          )}
        </ul>
        {/* <Hidden mdDown> */}
        {
          floorImage.url &&
          <FloorMap
            className="detail-list-map"
            center={getCenterItem()}
            floorImage={floorImage}
            markers={markers}
          />
        }

        {/* </Hidden> */}
      </div>
    )
  }, [
    allTimeValue,
    askForDeskReservationEntryTime,
    askForDeskReservationReason,
    disableUpdateReservation,
    exitTimePickerValue,
    floorImage,
    getCenterItem,
    getFormateRangeTime,
    getMinTime,
    goHealtForm,
    item,
    markers,
    onChangeTime,
    onCloseTimePicker,
    onContinueTimePicker,
    openTimePicker,
    setReason,
    t,
    timePickerValue,
    workplaceEnabledSymptomAssessment,
    workplaceReasons,
    enabledWorkplaceCustomIcon,
    workplaceCustomIconIndex
  ])

  const getUsage = useCallback(item => {
    const usage = item?.usage || []
    return usage.filter(u => u.id !== item.id)
  }, [])

  const handleAddRoomAttendee = useCallback((event) => {
    setRoomAttendeeEmail(event.target.value)
  }, []);

  const renderRoomAttendeesErrorMessages = useCallback(() => {
    if (emailFormatError) {
      return (
        <p className="attendees-error-message">
          {t('The email address entered is not valid.')}
        </p>
      )
    }
    if (emailAlreadyInList) {
      return (
        <p className="attendees-error-message">
          {t('The email address is already in the list.')}
        </p>
      )
    }
  }, [emailAlreadyInList, emailFormatError, t]);

  const handleAddRoomAttendeeKeyPress = useCallback(
    (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (emailRegex.test(roomAttendeeEmail)) {
          if (
            roomAttendeeEmail.length > 0 &&
            item.attendees &&
            item.attendees.includes(roomAttendeeEmail)
          ) {
            setEmailAlreadyInList(true);
            setEmailFormatError(false);
          } else {
            const newAttendees = item.attendees
              ? [...item.attendees, roomAttendeeEmail]
              : [roomAttendeeEmail];
            setItem({
              ...item,
              attendees: newAttendees,
            });
            setRoomAttendeeEmail("");
            setEmailFormatError(false);
            setEmailAlreadyInList(false);
            dispatch({
              type: Types.SET_SNACKBAR_DATA,
              payload: {
                open: true,
                message: t("Attendee added"),
                severity: "success",
                autoHideDuration: 6000,
                horizontal: "right",
              },
            });
          }
        } else if (
          roomAttendeeEmail.length > 0 &&
          !emailRegex.test(roomAttendeeEmail)
        ) {
          setEmailFormatError(true);
        }
      }
    },
    [dispatch, item, roomAttendeeEmail, t]
  );

  const getDetailDialogListRoom = useCallback(() => {
    return (
      <div className="detail-list-workplace">
        <ul className="detail-list">
          <li><EventIcon sx={iconSx} /> {t('date')}: <span>{formatDateISOShort(item.reservation_date)}</span> </li>
          <li><LocationCityIcon sx={iconSx} /> {t('place')}: <span>{getPlace(item)}</span></li>
          <li><GroupsIcon sx={iconSx} /> {t('room')}:<span>{item?.room?.name}</span></li>
          <li className='time-wrapper'>
            <WatchLater sx={iconSx} /> {t('hour')}:
            <span>
              <TextField
                value={getFormateRangeTime()}
                onClick={onChangeRoomTime}
                size="small"
                variant="filled"
                className="time-picker-button"
                disabled={disableUpdateReservation()}
              />
            </span>
            <ScheduleSelector
              handleClose={handleCloseScheduleSelector}
              handleBack={handleCloseScheduleSelector}
              onContinue={onContinueScheduleSelector}
              onChange={onChangeScheduleSelector}
              isToday={isToday(item.reservation_date)}
              prevReservations={getUsage(item)}
              isDateAvailable
              open={openScheduleSelector}
              entryTime={timePickerValue}
              exitTime={exitTimePickerValue}
              workStartTime={workplaceDefaultEntryTime}
              workEndTime={workplaceDefaultExitTime}
              maxReservationTime={item.max_reservation_time}
              date={item.reservation_date}
            />
          </li>
          <li>
            <Notes sx={iconSx} /> {t('reason')}:
            <span>
              <Select
                className="select-button"
                size="small"
                variant="filled"
                value={item.reason}
                onChange={event => setReason(event.target.value)}
                disabled={disableUpdateReservation()}
              >
                {workplaceReasons.map((r, index) =>
                  <MenuItem
                    key={index}
                    value={r}>
                    {r}
                  </MenuItem>
                )
                }
              </Select>
            </span>
          </li>
          {askForRoomReservationNotes &&
            <li>
              <Notes sx={iconSx} /> {t('Notes')}:
              <span>
                <TextField
                  value={item.notes || ""}
                  onChange={e => evalSetNotes(e.target.value, setNotes)}
                  autoComplete="off"
                  rows={3}
                  sx={{ width: 240 }}
                  fullWidth
                  multiline
                  size="small"
                  variant="filled"
                  className="time-picker-button"
                />
              </span>
            </li>
          }
          {enabledWorkplaceRoomReservationsAttendees &&
            <li>
              <Stack direction='row' columnGap={1} className="attendees">
                <AlternateEmail sx={iconSx} />
                <Stack direction='column' width={'100%'} rowGap={1}>
                  <span>{t("Attendees")}:</span>
                  <Stack direction='column' rowGap={1} >
                    {!disableUpdateReservation() &&
                      <Stack width='100%'>
                        <FormControl fullWidth>
                          <TextField
                            placeholder={t("Enter a name or e-mail address")}
                            variant="filled"
                            fullWidth
                            value={roomAttendeeEmail}
                            onChange={handleAddRoomAttendee}
                            onKeyPress={handleAddRoomAttendeeKeyPress}
                            error={emailFormatError || emailAlreadyInList}
                            inputProps={{ style: { padding: '8px' } }}
                            InputProps={{ style: { fontSize: 12 } }}
                          />
                        </FormControl>
                      </Stack>
                    }
                    {renderRoomAttendeesErrorMessages()}
                    {item.attendees && item.attendees.length !== 0 && (
                      <Stack
                        direction='row'
                        flexWrap='wrap'
                        gap={1}
                        maxHeight={isLarge ? '70px' : 'unset'}
                        mb={isLarge ? 1 : 0}
                      >
                        {item.attendees.map((attendee, index) => (
                          <Chip
                            className="attendee-chip"
                            key={index}
                            label={attendee}
                            style={{ width: 'max-content' }}
                            {...(!disableUpdateReservation() && {
                              onDelete: () => {
                                const newAttendees = item.attendees.filter(
                                  (_, i) => i !== index
                                );
                                setItem({
                                  ...item,
                                  attendees: newAttendees,
                                });
                              },
                            })}
                            color="primary"
                            variant="outlined"
                          />
                        ))}
                      </Stack>
                    )}
                  </Stack>
                </Stack>
              </Stack>
            </li>
          }
          {item.meeting_url &&
            <li>
              <Stack
                direction='row'
                columnGap={1}
                width={'100%'}
                alignItems={'center'}
              >
                <LinkIcon sx={iconSx} />
                <span>{item.meeting_url}</span>
              </Stack>
            </li>
          }
        </ul>
        {
          floorImage.url &&
          <FloorMap
            className="detail-list-map"
            center={getCenterItem()}
            floorImage={floorImage}
            markers={markers}
          />
        }
      </div>
    )
  }, [askForRoomReservationNotes, disableUpdateReservation, exitTimePickerValue, floorImage, getCenterItem, getFormateRangeTime, getUsage, handleCloseScheduleSelector, item, markers, onChangeRoomTime, onChangeScheduleSelector, onContinueScheduleSelector, openScheduleSelector, setNotes, setReason, t, timePickerValue, workplaceDefaultEntryTime, workplaceDefaultExitTime, workplaceReasons, enabledWorkplaceRoomReservationsAttendees, emailAlreadyInList, emailFormatError, handleAddRoomAttendee, handleAddRoomAttendeeKeyPress, renderRoomAttendeesErrorMessages, roomAttendeeEmail, isLarge])

  const getDetailDialogListCafeteria = useCallback(() => {
    const orderPrice = item.meals?.reduce((acc, meal) => acc + meal.price, 0)
    const orderTaxes = item.meals?.reduce((acc, meal) => acc + meal.taxes, 0)
    const totalOrderPrice = orderPrice + orderTaxes

    return (
      <Box
        className="detail-list-wrapper"
        id="ticket-print-area"
      >
        <ul className="detail-list">
          <li>
            {t('Cafeteria')}
          </li>
          <li>
            <EventIcon sx={iconSx} />
            <span>
              {'Fecha:'}
            </span>
            <span>
              <bold>
                {formatDateMedMed(item.order_date)}
              </bold>
            </span>
          </li>
          {!!item.order_time &&
            <li>
              <WatchLaterIcon sx={iconSx} />
              <span>
                {formatDateISOtoAMPM(item.order_time)}
              </span>
            </li>
          }
          <li>
            <PlaceIcon sx={iconSx} />
            <span>
              {item.site?.name}
            </span>
          </li>
          <li>
            <ListAltIcon sx={iconSx} />
            <ul className="detail-list">
              {item.meals?.map((meal, index) => {
                return (
                  <li key={`${meal.id}-${index}`}>
                    <span>{meal.name}</span>
                  </li>
                )
              })}
              <li>{`Total: ${item.site?.currency}${totalOrderPrice}`}</li>
            </ul>
          </li>

        </ul>
        <Box
          display="flex"
          width={'100%'}
          justifyContent={'center'}
        >
          <img
            src={orderQR}
            alt="Order-QR"
            width={'163px'}
            height={'163px'}
          />
        </Box>
      </Box>
    )
  }, [item, t, orderQR])

  const getDetailDialogList = useCallback(() => {
    if (item.type === PARKING_RESERVATION) return getDetailDialogListParking()
    if (item.type === DESK_RESERVATION) return getDetailDialogListWorkplace()
    if (item.type === ROOM_RESERVATION) return getDetailDialogListRoom()
    if (item.type === CAFETERIA_RESERVATION) return getDetailDialogListCafeteria()
  }, [getDetailDialogListParking, getDetailDialogListRoom, getDetailDialogListWorkplace, item, getDetailDialogListCafeteria])

  const onCloseDetail = useCallback(() => {
    setFloorImage({})
    setOpen(false)
    setEmailFormatError(false)
    setEmailAlreadyInList(false)
  }, [])

  const openDirections = (event, { parking_lot }) => {
    stopEvent(event)
    if (!parking_lot) return

    const {
      latitude = '0.0',
      longitude = '0.0'
    } = parking_lot

    window.open(
      `${GOOGLE_MAP_URL}/@${latitude},${longitude},15z`,
      '_blank'
    )
  }

  const getIsLargeHeaderIcon = useCallback(() => {
    if (item.type === PARKING_RESERVATION) return <DirectionsCar className="dialog-vehicle" />
    if (item.type === DESK_RESERVATION) return (
      enabledWorkplaceCustomIcon &&
        workplaceCustomIconIndex !== undefined ?
        <CustomWorkplaceIcons
          index={workplaceCustomIconIndex}
          width={24}
          className="dialog-vehicle"
        /> :
        <AirlineSeatReclineNormalIcon className="dialog-vehicle" />
    )
    if (item.type === ROOM_RESERVATION) return <GroupsIcon className="dialog-vehicle" />
    if (item.type === CAFETERIA_RESERVATION) return <RestaurantIcon className="dialog-vehicle" />
  }, [item.type, enabledWorkplaceCustomIcon, workplaceCustomIconIndex])

  const evalGeoposition = useCallback(item => {
    if (isWorkplace(item)) {
      const { latitude = 0, longitude = 0 } = sites.find(s => s.id === item.site.id) || {}
      setSiteGeo({ latitude, longitude })
    } else setSiteGeo({ latitude: 0, longitude: 0 })
  }, [sites])

  const markArrival = useCallback(item => {
    evalGeoposition(item)
    setItem(item)
    setOpenMarkArrivalModal(true)
  }, [evalGeoposition])

  const detailDialog = useCallback(() => {
    return (
      <Dialog
        className="dialog-detail"
        fullScreen={!isLarge}
        fullWidth={true}
        maxWidth="md"
        open={open}
        onClose={onCloseDetail}
        aria-labelledby="responsive-dialog-title">
        <DialogContent className="dialog-detail-content">
          <h3 className='dialog-title'>
            {isLarge ?
              getIsLargeHeaderIcon() :
              <IconButton
                className="arrow-back-button"
                aria-label="back"
                color="primary"
                onClick={onCloseDetail}>
                <Arrow className="arrow-back" />
              </IconButton>
            }

            {item.type === PARKING_RESERVATION && t('PARKING')}
            {item.type === DESK_RESERVATION && (workplaceCustomLabel ? workplaceCustomLabel.toUpperCase() : t('WORKPLACE'))}
            {item.type === ROOM_RESERVATION && t('ROOM')}

            {isLarge &&
              <IconButton
                className="close-button"
                aria-label="close"
                onClick={onCloseDetail} >
                <CloseIcon />
              </IconButton>
            }
          </h3>
          <h4 className='dialog-subtitle'>
            <FiberManualRecordIcon
              className={item.status}
              sx={iconSx}
            />
            {t(item.status)}
          </h4>
          <Divider className="detail-hr" />
          {getDeniedReason()}
          {getDetailDialogList()}
          <Stack
            justifyContent="space-between"
            direction={"row"}
            className={`
              detail-footer
              ${item.type !== PARKING_RESERVATION ? 'workplace' : ''}
            `}
          >
            <Stack
              direction={isLarge ? "row" : "column"}
              justifyContent={isLarge ? 'flex-end' : 'center'}
              width={'100%'}
              marginTop={isLarge ? 0 : 2}
              spacing={2}
            >
              <Button
                disabled={disableUpdateReservation()}
                variant="contained"
                onClick={onSaveReservation}
                width={isLarge ? '300px' : '100%'}
              >
                {t('save changes')}
              </Button>
              <Button
                variant="outlined"
                color="error"
                onClick={onRemoveReservation}
                width={isLarge ? 'auto' : '100%'}
              >
                {t('cancel reservation')}
              </Button>
            </Stack>
            {/* {item.type === PARKING_RESERVATION &&
              <Button
                variant="outlined"
                color="primary"
                onClick={() => openDirections(item)}
              >
                {t('how to get')}
              </Button>
            } */}
          </Stack>
        </DialogContent>
      </Dialog>
    )
  }, [disableUpdateReservation, getDeniedReason, getDetailDialogList, getIsLargeHeaderIcon, isLarge, item, onSaveReservation, open, t, onRemoveReservation, onCloseDetail, workplaceCustomLabel])

  const isLastWorkplaceWithParking = useCallback(() => {
    const isValidWorkplace = item && (isDesk(item) || item.type === ROOM_RESERVATION)
    if (!isValidWorkplace) return false

    const hasEnabledFeatures = enabledPD || enabledPR
    if (!hasEnabledFeatures) return false

    const itemDate = item.reservation_date

    const workplaceReservationsForDate = reservationsAndAssignations.filter(reservation => {
      const isWorkplaceType =
        reservation.type === DESK_RESERVATION ||
        reservation.type === ROOM_RESERVATION
      const sameDate = reservation.reservation_date === itemDate

      return isWorkplaceType && sameDate
    })

    const parkingReservationsForDate = reservationsAndAssignations.filter(reservation => {
      const isParkingType = reservation.type === PARKING_RESERVATION
      const sameDate = reservation.reservation_date === itemDate
      return isParkingType && sameDate
    })

    return workplaceReservationsForDate.length === 1 && parkingReservationsForDate.length > 0
  }, [item, enabledPD, enabledPR, reservationsAndAssignations])

  const timeItemParking = useCallback(() => {
    if (openMarkArrivalModal) return <li><WatchLaterIcon sx={iconSx} /> {t('hour')}: <strong>{getFormateRangeSessionTime(item.session, t)}</strong></li>
    if ((askForParkingReservationEntryTime && enabledMultiplesParkingReservations) || isLastWorkplaceWithParking()) return <li><WatchLater sx={iconSx} /> {t('hour')}: <strong>{formatTime(item.entry_time)} - {formatTime(item.exit_time)}</strong></li>
    if (askForParkingReservationEntryTime) return <li><WatchLater sx={iconSx} />{t('hour')}: <strong>{formatTime(item.entry_time)}</strong></li>
  }, [askForParkingReservationEntryTime, enabledMultiplesParkingReservations, item, openMarkArrivalModal, t, isLastWorkplaceWithParking])

  const timeItem = useCallback(() => {
    if (openMarkArrivalModal) return <li><WatchLaterIcon sx={iconSx} /> {t('hour')}: <strong>{getFormateRangeSessionTime(item.session, t)}</strong> </li>
    if (askForDeskReservationEntryTime) return <li><WatchLaterIcon sx={iconSx} /> {t('hour')}: <strong>{getFormateRangeTime()}</strong> </li>
  }, [askForDeskReservationEntryTime, getFormateRangeTime, item, openMarkArrivalModal, t])

  const dialogListParking = useCallback((parkingItem = item) => {
    return (
      <ul className="detail-list">
        <li>
          <strong>{parkingItem.parking_space.parking_lot_name}</strong>
        </li>
        <li><EventIcon sx={iconSx} /> {t('date')}: <strong>{formatDateISOShort(parkingItem.reservation_date)}</strong> </li>
        {timeItemParking(parkingItem)}
        <li><Place sx={iconSx} /> {t('space')}: <strong>{parkingItem?.parking_space?.parking_space_label}</strong></li>
        {askForParkingReservationVehicle && <li><DirectionsCar sx={iconSx} width={10} /> {t('vehicle')}: <strong>{parkingItem?.vehicle?.license_plate}</strong></li>}
      </ul>
    )
  }, [askForParkingReservationVehicle, item, t, timeItemParking])

  const dialogListWorkplace = useCallback(() => {
    return (
      <ul className="detail-list">
        <li>
          <strong>{workplaceCustomLabel ? workplaceCustomLabel : t('workplace')}</strong>
        </li>
        <li><EventIcon sx={iconSx} />{t('date')}: <strong>{formatDateISOShort(item.reservation_date)}</strong> </li>
        {timeItem()}
        <li><LocationCityIcon sx={iconSx} />{t('place')}: <strong>{getPlace(item)}</strong> </li>
        <li>
          {enabledWorkplaceCustomIcon && workplaceCustomIconIndex !== undefined ?
            <CustomWorkplaceIcons
              index={workplaceCustomIconIndex}
              sx={iconSx}
            /> :
            <AirlineSeatReclineNormalIcon sx={iconSx} />
          }
          {t('desk')}: <strong>{item?.desk?.name}</strong>
        </li>
      </ul>
    )
  }, [item, t, timeItem, enabledWorkplaceCustomIcon, workplaceCustomIconIndex, workplaceCustomLabel])

  const dialogListRoom = useCallback(() => {
    return (
      <ul className="detail-list">
        <li><strong>{t('room')}</strong></li>
        <li><EventIcon sx={iconSx} />{t('date')}: <strong>{formatDateISOShort(item.reservation_date)}</strong> </li>
        <li><WatchLaterIcon sx={iconSx} />{t('hour')}: <strong>{getFormateRangeTime()}</strong> </li>
        <li><GroupsIcon sx={iconSx} />{t('room')}: <strong>{item?.room?.name}</strong> </li>
      </ul>
    )
  }, [getFormateRangeTime, item, t])

  const dialogListCafeteria = useCallback(() => {
    return (
      <ul className='detail-list'>
        <li>{t('Cafeteria')}</li>
        <li><PlaceIcon sx={iconSx} />{item.site?.name}</li>
        {!!item.order_time && <li><WatchLaterIcon sx={iconSx} />{item.order_time}</li>}
        <li className='order-detail-list'>
          <ListAltIcon sx={iconSx} />
          <ul>
            {item.meals?.map((meal, index) => {
              return (
                <li key={`${index}-${meal.id}`}>
                  <span>{meal.name}</span>
                </li>
              )
            })}
          </ul>
        </li>
      </ul>
    )
  }, [item, t])

  const detailDialogCafeteria = useCallback(() => {
    const orderPrice = item.meals?.reduce((acc, meal) => acc + meal.price, 0)
    const orderTaxes = item.meals?.reduce((acc, meal) => acc + meal.taxes, 0)
    const totalOrderPrice = orderPrice + orderTaxes

    const diffInMinutes = getDiffInMinutesFromNow(item.order_time)

    return (
      <Dialog
        className="dialog-detail"
        fullScreen={!isLarge}
        fullWidth={true}
        maxWidth="sm"
        open={open}
        onClose={onCloseDetail}
        aria-labelledby="responsive-dialog-title">
        <DialogContent className="dialog-detail-content">
          <Stack
            direction={"row"}
            columnGap={'12px'}
            padding={'18px'}
            alignItems={'center'}
          >
            <h2>
              {isLarge ?
                getIsLargeHeaderIcon() :
                <IconButton
                  className="arrow-back-button"
                  aria-label="back"
                  color="primary"
                  onClick={onCloseDetail}>
                  <Arrow className="arrow-back" />
                </IconButton>
              }
            </h2>
            <h2 className='dialog-title'>
              {t('Cafeteria')}
            </h2>
            <h2>
              {isLarge &&
                <IconButton
                  className="close-button"
                  aria-label="close"
                  onClick={onCloseDetail} >
                  <CloseIcon />
                </IconButton>
              }
            </h2>
            <h4 className='dialog-subtitle'>
              {item.status === REJECTED &&
                <StatusChip
                  status={item.status}
                  label={t("Reservation deleted by the administrator")}
                />
              }
              {item.status === 'PENDING' && item.order_time &&
                isToday(item.order_date) &&
                diffInMinutes > cafeteriaMinTimeForCancelOrder &&
                <StatusChip
                  label={t("Expired reservation")}
                />
              }
            </h4>
          </Stack>

          <Divider />

          <Box
            className="cafeteria-detail-list-wrapper"
            id="ticket-print-area"
          >
            {item.order_time &&
              isToday(item.order_date) &&
              diffInMinutes > cafeteriaMinTimeForCancelOrder &&
              <Alert severity="error" icon={false} style={{ color: "#CD2A26", marginBottom: '16px' }} >
                {t('This reservation has expired, as the time selected for its use has passed.')}
              </Alert>
            }
            <ul className="detail-list">
              <li>
                <EventIcon sx={iconSx} />
                <span>
                  {`${t('Date')}: `}
                </span>
                <span id='order-data'>
                  {formatDateMed(item.order_date)}
                </span>
              </li>
              {!!item.order_time &&
                <li>
                  <WatchLaterIcon sx={iconSx} />
                  <span>
                    {`${t('Pick up time')}: `}
                  </span>
                  <span id='order-data'>
                    {item.order_time}
                  </span>
                </li>
              }
              <li>
                <PlaceIcon sx={iconSx} />
                <span>
                  {`${t('Cafeteria')}: `}
                </span>
                <span id='order-data'>
                  {item.site?.name}
                </span>
              </li>
              <li id="cafeteria-order-list">
                <ListAltIcon sx={iconSx} />
                <span>
                  {`${t('Order')}: `}
                </span>
                <ul>
                  {item.meals?.map((meal, index) => {
                    return (
                      <li key={`${meal.id}-${index}`}>
                        <span id='order-data'>{meal.name}</span>
                      </li>
                    )
                  })}
                  <li id='order-data'>{`Total: ${item.site?.currency} ${totalOrderPrice}`}</li>
                </ul>
              </li>
            </ul>
            <Box
              display="flex"
              width={'100%'}
              justifyContent={'center'}
            >
              {orderQR ?
                <img
                  src={orderQR}
                  alt="Order-QR"
                  width={'163px'}
                  height={'163px'}
                />
                :
                <Skeleton
                  variant='rectangular'
                  width={'163px'}
                  height={'163px'}
                />
              }
            </Box>
            {item.status === REJECTED &&
              <Alert severity="error" icon={false} style={{ color: "#CD2A26", marginBottom: '16px' }} >
                {t('The QR has been invalidated due to cancellation of the reservation by the administrator.')}
              </Alert>
            }
          </Box>
          <Stack
            direction={"row"}
            className={`detail-footer`}
            width={'100%'}
            justifyContent={'center'}
          >
            <Stack
              direction={"column"}
              spacing={2}
              width={'350px'}
            >
              {item.status !== REJECTED &&
                <Button
                  disabled={disableUpdateReservation()}
                  variant="outlined"
                  onClick={downloadTicketAsImage}
                  width={'100%'}
                >
                  <Download
                    width={'24px'}
                  />
                  {t('Download')}
                </Button>
              }

              {item.order_time &&
                ((!isToday(item.order_date)) ||
                  (isToday(item.order_date) && diffInMinutes < -cafeteriaMinTimeForCancelOrder)) &&
                <Button
                  variant="outlined"
                  color="error"
                  onClick={onRemoveReservation}
                  width={'100%'}
                >
                  {t('cancel reservation')}
                </Button>
              }
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    )
  }, [item, t, disableUpdateReservation, getIsLargeHeaderIcon, isLarge, onCloseDetail, onRemoveReservation, orderQR, cafeteriaMinTimeForCancelOrder, open])

  const getDialogList = useCallback(() => {
    if (isLastWorkplaceWithParking()) {

      const CombinedList = () => {
        const parkingReservation = reservationsAndAssignations.find(reservation =>
          reservation.reservation_date === item.reservation_date &&
          reservation.type === PARKING_RESERVATION
        )

        return (
          <Stack direction="row" spacing={2} width={'100%'} justifyContent={'center'}>
            <Box className="parking-reservation" width={'50%'}>
              {parkingReservation && dialogListParking(parkingReservation)}
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box className="workplace-reservation" width={'50%'}>
              {isDesk(item) ? dialogListWorkplace() : dialogListRoom()}
            </Box>
          </Stack>
        )
      }

      return <CombinedList />
    }

    if (isParking(item)) return dialogListParking()
    if (isDesk(item)) return dialogListWorkplace()
    if (item.type === ROOM_RESERVATION) return dialogListRoom()
    if (isCafeteriaOrder(item)) return dialogListCafeteria()
  }, [
    item,
    dialogListParking,
    dialogListRoom,
    dialogListWorkplace,
    dialogListCafeteria,
    isLastWorkplaceWithParking,
    reservationsAndAssignations
  ])


  const updateAcceptPrivacyNotice = () => {
    const data = {
      user: {
        has_accepted_privacy_policy: true
      }
    }

    api.put('profile', data)
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success) return
        dispatch({
          type: Types.SET_TRUE_HAS_ACCEPTED_PRIVACY_POLICY
        })
      })
      .catch(error => defaultCatch(error, navigate, updateAcceptPrivacyNotice))
  }

  const onAcceptPrivacyNotice = () => {
    setOpenPrivacyNotice(false)
    updateAcceptPrivacyNotice()
  }

  const onCancelPrivacyNotice = () => {
    if (typePrivacyNotice === WARNING) {
      logout(navigate)
      return
    }

    setTitlePrivacyNotice(t("Are you sure you don't want to accept the privacy notice?"))
    setparagraphPrivacyNotice(t("If you reject the privacy and data use notice, we will automatically close your session."))
    setTypePrivacyNotice(WARNING)
  }

  const removeDialog = useCallback(() => {

    const parkingReservationItem = reservationsAndAssignations.find(reservation =>
      reservation.reservation_date === item.reservation_date &&
      reservation.type === PARKING_RESERVATION
    )

    return (
      <Dialog
        fullWidth={true}
        maxWidth={isLastWorkplaceWithParking() ? 'md' : 'sm'}
        open={remove}
        onClose={() => setRemove(false)}
        aria-labelledby="responsive-dialog-title">
        <DialogContent className="dialog-info-content">
          <Stack className="detail-header">
            <InfoOutlinedIcon />
            {isLastWorkplaceWithParking() ? t('combined cancel dialog', { parking: parkingReservationItem.parking_space.parking_lot_name, workplace: item.type === DESK_RESERVATION ? item.desk.name : item.room.name }) : t('cancel reservation')}
          </Stack>
          <p>{isLastWorkplaceWithParking() ? t('combined cancel dialog paragraph', { workplace: item.type === DESK_RESERVATION ? item.desk.name : item.room.name }) : t('cancel reservation paragraph')}</p>
          <div className="detail-remove-info">
            {getDialogList()}
          </div>
          <Stack spacing={2} direction="row" className='detail-footer'>
            <Button
              variant="outlined"
              onClick={() => setRemove(false)} >
              {t('go back')}
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={onCancel} >
              {t('cancel reservation')}
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    )
  }, [getDialogList, isLastWorkplaceWithParking, onCancel, remove, t, reservationsAndAssignations, item])

  const markArrivalModal = useCallback(() => {
    return (
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={openMarkArrivalModal}
        onClose={() => setOpenMarkArrivalModal(false)}
        aria-labelledby="responsive-dialog-title">
        <DialogContent className="dialog-info-content">
          <div className="detail-header">
            <InfoOutlinedIcon />
            {item?.has_session || item?.session?.start_time ? t('Check Out') : t('Mark arrival')}
          </div>
          <Divider className="detail-hr" />
          <div className="detail-remove-info">
            {getDialogList()}
          </div>
          <Stack spacing={2} direction="row" className='detail-footer'>
            <Button
              variant="outlined"
              onClick={() => setOpenMarkArrivalModal(false)} >
              {t('go back')}
            </Button>
            <Button
              variant="contained"
              onClick={onMarkArrival} >
              {t('confirm')}
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    )
  }, [getDialogList, item, onMarkArrival, openMarkArrivalModal, t])

  return (
    <Layout title={t('my reservations')}>
      <div className="Reservations">
        <Tabs
          className="cp-tabs"
          value={tab}
          onChange={(_, value) => setTab(value)}
        >
          <Tab className="cp-tab" value="upcoming" label={t('upcoming')} />
        </Tabs>
        <Filters
          type={typeFilter}
          date={dateFilter}
          status={statusFilter}
          setType={onSetType}
          setStatus={setStatusFilter}
          setDate={setDateFilter}
          onFilter={onFilter}
        />
        <CardWrapper
          refNode={cardWrapperRef}
          items={reservations}
          openItem={openItem}
          openDirections={openDirections}
          removeItem={removeItem}
          markArrival={markArrival}
        />
        <Hidden mdUp>
          <div className="new-reservation-footer">
            <Button
              className="cp-button"
              variant="contained"
              startIcon={<Add />}
              onClick={() => navigate("/new-reservation")}
            >
              {t('new reservation')}
            </Button>
          </div>
        </Hidden>
      </div>
      {item && item.type !== CAFETERIA_RESERVATION ?
        detailDialog()
        :
        detailDialogCafeteria()
      }
      {removeDialog()}
      {markArrivalModal()}
      <ModalNotification
        open={openPrivacyNotice}
        title={titlePrivacyNotice}
        paragraph={paragraphPrivacyNotice}
        message={messagePrivacyNotice}
        type={typePrivacyNotice}
        onAccept={onAcceptPrivacyNotice}
        onCancel={onCancelPrivacyNotice}
      />
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    profile: state.profile,
    sites: state.workplace.sites,
    enabled_parking: state.profile.enabled_parking,
    enabled_workplace: state.profile.enabled_workplace,
    enabled_room: state.profile.enabled_room_reservations,
    askForRoomReservationNotes: state.profile.company.ask_for_room_reservation_notes,
    askForDeskReservationReason: state.profile.company.ask_for_desk_reservation_reason,
    askForDeskReservationEntryTime: state.profile.company.ask_for_desk_reservation_entry_time,
    askForParkingReservationReason: state.profile.company.ask_for_parking_reservation_reason,
    askForParkingReservationEntryTime: state.profile.company.ask_for_parking_reservation_entry_time,
    enabledMultiplesParkingReservations: state.profile.company.enabled_multiples_parking_reservations,
    askForParkingReservationVehicle: state.profile.company.ask_for_parking_reservation_vehicle,
    workplaceEnabledSymptomAssessment: state.profile.company.workplace_enabled_symptom_assessment,
    enabledPrivacyDataApproval: state.profile.company.enabled_privacy_data_approval,
    hasAcceptedPrivacyPolicy: state.profile.has_accepted_privacy_policy,
    reservationsStatic: state.general.reservationsAndAssignations,
    workplaceDefaultEntryTime: getWorkplaceDefaultEntryTime(state),
    workplaceDefaultExitTime: getWorkplaceDefaultExitTime(state),
    userId: state.profile.id,
    enabled_cafeteria: state.profile.company.enabled_cafeteria,
    cafeteriaMinTimeForCancelOrder: state.profile.company.cafeteria_min_time_for_cancel_order,
    workplaceCustomLabel: state.profile.company.workplace_custom_label,
    workplaceCustomIconIndex: state.profile.company.workplace_custom_icon_index,
    enabledWorkplaceCustomIcon: state.profile.company.enabled_workplace_custom_icon,
    enabledWorkplaceRoomReservationsAttendees: state.profile.company.enabled_workplace_room_reservations_attendees,
    enabledPR: ENABLED_PR.includes(state.profile.domain),
    enabledPD: ENABLED_PD.includes(state.profile.domain),
    reservationsAndAssignations: state.general.reservationsAndAssignations
  }
}

const mapDispatchToProps = {
  dispatch,
  logout,
  setLoading,
  defaultSuccess,
  defaultCatch
}


export default connect(mapStateToProps, mapDispatchToProps)(Reservations)
