import MobileDetect from 'mobile-detect'

import {
  getUpcomingDates,
  removeTimezoneFromISO,
  formatNoTZTimeFromISOtoAMPM,
  stringHourToJsDate
} from './FormatDate'

import {
  AirConditioner,
  Blackboard,
  Plug,
  Projector,
  VideoConference,
  Wifi,
  Monitor,
  StandDesk,
  DoubleMonitor,
  WideDesk,
  Print,
  ErgonomicChair,
  Window,
  NaturalLight,
  MacStation,
  Footrest,
  Keyboard,
  AdditionalTable,
  ProjectionScreen,
  ExpandableRoom,
  Phone
} from '../components/Svgs/Amenities'

import {
  INITIAL_OFFSET,
  REGEX_HTTP_URL,
  PARKING_RESERVATION,
  PARKING_ASSIGNATION,
  DESK_RESERVATION,
  DESK_ASSIGNATION,
  ROOM_RESERVATION,
  CAFETERIA_RESERVATION,
  DISTANCE_TOLERANCE_KM_MOVIL,
  DISTANCE_TOLERANCE_KM_WEB,
  OK,
  ERROR,
  UNSUPPORTED,
  MAX_NOTES_LENGTH,
  DISABLED,
  FIVE_PM,
  EIGHT_AM
} from '../../variables'

import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';


const getCurrentBrowser = () => {
  var ua = navigator.userAgent
  var tem
  var M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || []
    return 'IE ' + (tem[1] || '')
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
    if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera')
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?']
  if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1])
  return M.join(' ');
}

const getPlace = item => {
  const {
    site = { name: '' },
    building = { name: '' },
    floor = { name: '' }
  } = item
  return `${site.name}, ${building.name}, ${floor.name}`
}

const sortItems = (items = []) => {
  return items.sort((a, b) => {
    const _a = a.name.toLowerCase()
    const _b = b.name.toLowerCase()

    if (_a < _b) return -1
    else if (_a > _b) return 1
    return 0
  })
}

const getFullNameGuest = params => {
  const {
    first_name,
    last_name_one,
    last_name
  } = params

  return `${first_name} ${last_name_one ? last_name_one : last_name}`
}

const stopEvent = e => {
  if (e.preventDefault !== undefined) e.preventDefault()
  if (e.stopPropagation !== undefined) e.stopPropagation()
}

const getNumberToWeekDay = (n, t) => {
  if (n === 1) return t('Monday')
  if (n === 2) return t('Tuesday')
  if (n === 3) return t('Wednesday')
  if (n === 4) return t('Thursday')
  if (n === 5) return t('Friday')
  if (n === 6) return t('Saturday')
  if (n === 7) return t('Sunday')
  return ''
}

const getAmenitieImage = (i, t) => {
  if (i === 0) return <label title={t("Airconditioner")}> <AirConditioner width={16} /></label>
  if (i === 1) return <label title={t("Blackboard")}> <Blackboard width={16} /></label>
  if (i === 2) return <label title={t("Plug")}> <Plug width={16} /></label>
  if (i === 3) return <label title={t("Projector")}> <Projector width={16} /></label>
  if (i === 4) return <label title={t("Videoconference")}> <VideoConference width={16} /></label>
  if (i === 5) return <label title={t("Wifi")}> <Wifi width={16} /></label>
  if (i === 6) return <label title={t("Monitor")}><Monitor width={16} /></label>
  if (i === 7) return <label title={t("Expandable room")}><ExpandableRoom width={20} /></label>
  if (i === 8) return <label title={t("Phone")}><Phone width={16} /></label>
}

const getDeskAmenities = (i, t) => {
  if (i === 0) return <div key={i} className='amenitie'><StandDesk width={16} /> <label>{t('Stand desk')}</label></div>
  if (i === 1) return <div key={i} className='amenitie'><Monitor width={16} /> <label>{t('Monitor')}</label></div>
  if (i === 2) return <div key={i} className='amenitie'><DoubleMonitor width={32} /> <label>{t('Double monitor')}</label></div>
  if (i === 3) return <div key={i} className='amenitie'><WideDesk width={16} /> <label>{t('Wide desk')}</label></div>
  if (i === 4) return <div key={i} className='amenitie'><Print width={16} /> <label>{t('Print')}</label></div>
  if (i === 5) return <div key={i} className='amenitie'><ErgonomicChair width={16} /> <label>{t('Ergonomic chair')}</label></div>
  if (i === 6) return <div key={i} className='amenitie'><Window width={16} /> <label>{t('Window')}</label></div>
  if (i === 7) return <div key={i} className='amenitie'><NaturalLight width={16} /> <label>{t('Natural light')}</label></div>
  if (i === 8) return <div key={i} className='amenitie'><MacStation width={16} /> <label>{t('Mac station')}</label></div>
  if (i === 9) return <div key={i} className='amenitie'><Footrest width={16} /> <label>{t('Footrest')}</label></div>
  if (i === 10) return <div key={i} className='amenitie'><Keyboard width={16} /> <label>{t('Keyboard')}</label></div>
  if (i === 11) return <div key={i} className='amenitie'><AdditionalTable width={16} /> <label>{t('Additional table')}</label></div>
  return <div key={i} className='amenitie'><ProjectionScreen width={16} /> <label>{t('Projection screen')}</label></div>
}

const getParkingReasons = t => [
  t('private meeting'),
  t('workday'),
  t('training'),
  t('special event'),
  t('others')
]

const getWorkplaceReasons = t => [
  t('private meeting'),
  t('workday'),
  t('training'),
  t('special event'),
  t('others')
]

const getPosition = (m, imageHeight) => {
  return [
    - m.y + imageHeight - INITIAL_OFFSET,
    m.x + INITIAL_OFFSET
  ]
}

const parseMessageHtml = message => {
  const regex = REGEX_HTTP_URL;
  return message.replace(regex, (match) => {
    return `<a href="${match}" target="_blank">${match}</a>`;
  })
}

const blobToBase64 = blob => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = function (event) {
      const base64Image = event.target.result
      resolve(base64Image)
    }

    reader.onerror = function (error) {
      reject(error)
    }

    reader.readAsDataURL(blob)
  })
}

const cleanReservation = (reservations = [], params = {}) => {
  const { type } = params
  const _reservations = reservations.filter(r => r.status !== DISABLED)
  return _reservations.map(r => ({
    ...r,
    type: type ? type : r.type,
    entry_time: removeTimezoneFromISO(r.entry_time),
    exit_time: removeTimezoneFromISO(r.exit_time)
  }))
}

const cleanAssignations = (assignation = {}, params = {}) => {
  const { type } = params
  if (!assignation.dates) return []

  let session = type === PARKING_ASSIGNATION ?
    assignation.parking_session :
    assignation.session

  const dates = getUpcomingDates(assignation.dates)
  return dates.map(date => ({
    ...assignation,
    type,
    session,
    reservation_date: date
  }))
}

const cleanCafeteriaOrders = (orders = [], params = {}) => {
  const { type } = params

  const _orders = orders.filter(o => o.status !== DISABLED)
  return _orders.map(o => ({
    ...o,
    type,
    order_time: o.order_time ? formatNoTZTimeFromISOtoAMPM(o.order_time) : null
  }))
}

async function getCurrentLocation() {
  let latitude = 0
  let longitude = 0

  if ('geolocation' in navigator) {
    try {
      const position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject)
      })
      latitude = position.coords.latitude;
      longitude = position.coords.longitude;
      return { status: OK, latitude, longitude }
    } catch (error) {
      const message = `Error while obtaining geolocation: ${error?.message}`
      return { status: ERROR, message, latitude, longitude }
    }
  } else {
    const message = "Geolocation is not available in this browser."
    return { status: UNSUPPORTED, message, latitude, longitude }
  }
}


function calculateDistanceKM(position1, position2) {  // Kilometers
  const earthRadius = 6371
  const {
    latitude: lat1,
    longitude: lon1
  } = position1

  const {
    latitude: lat2,
    longitude: lon2
  } = position2

  const latRad1 = (Math.PI / 180) * lat1
  const lonRad1 = (Math.PI / 180) * lon1
  const latRad2 = (Math.PI / 180) * lat2
  const lonRad2 = (Math.PI / 180) * lon2

  const latDiff = latRad2 - latRad1
  const lonDiff = lonRad2 - lonRad1

  const a =
    Math.sin(latDiff / 2) ** 2 +
    Math.cos(latRad1) *
    Math.cos(latRad2) *
    Math.sin(lonDiff / 2) ** 2
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const distance = earthRadius * c

  // Distance in kilometers
  return distance
}

const isMobile = () => {
  const md = new MobileDetect(window.navigator.userAgent)
  return md.mobile()
}

const isNear = (position1, position2) => {
  const distance = calculateDistanceKM(position1, position2)
  const distanceTolerance = isMobile() ?
    DISTANCE_TOLERANCE_KM_MOVIL :
    DISTANCE_TOLERANCE_KM_WEB

  return distance <= distanceTolerance
}

const evalSetNotes = (notes, setNotes) => {
  if (notes.length > MAX_NOTES_LENGTH) return
  setNotes(notes)
}

const isParking = item => item.type === PARKING_RESERVATION || item.type === PARKING_ASSIGNATION
const isWorkplace = item => item.type === DESK_RESERVATION || item.type === DESK_ASSIGNATION || item.type === ROOM_RESERVATION
const isDesk = item => item.type === DESK_RESERVATION || item.type === DESK_ASSIGNATION
const isAssignation = item => item.type === DESK_ASSIGNATION || item.type === PARKING_ASSIGNATION
const isReservation = item => item.type === PARKING_RESERVATION || item.type === DESK_RESERVATION
const isCafeteriaOrder = item => item.type === CAFETERIA_RESERVATION
const defaultFunction = () => { }

const getLocalitation = (reservation = {}) => {
  const site = reservation?.site?.name || ''
  const building = reservation.building?.name || ''
  const floor = reservation?.floor?.name || ''

  const items = [site, building, floor]
  return items.filter(i => i !== '').join(', ')
}

const autoSetPlace = (sites = [], {
  setSite = defaultFunction,
  setBuildings = defaultFunction,
  setBuilding = defaultFunction,
  setFloors = defaultFunction,
  setFloor = defaultFunction,
  setFloorMapId = defaultFunction
}) => {
  if (sites.length === 1) {
    const siteItem = sites[0]

    setSite(siteItem.id)
    setBuildings(sortItems(siteItem.buildings))

    const buildingItem = siteItem.buildings[0]
    if (siteItem.buildings.length === 1) {
      setBuilding(buildingItem.id)
      setFloors(sortItems(buildingItem.floors))

      const floorItem = buildingItem.floors[0]
      if (buildingItem.floors.length === 1) {
        setFloor(floorItem.id)
        setFloorMapId(floorItem.floor_map.id)
      }
    }
  }

  return {

  }
}

const downloadTicketAsImage = () => {
  const element = document.getElementById('ticket-print-area') // You should add this id to the element you want to print
  html2canvas(element).then((canvas) => {
    canvas.toBlob((blob) => {
      saveAs(blob, "ticket.jpg")
    })
  })
}

const downloadQRAsImage = (visitorName, date, onDownloadComplete = () => { }) => {
  const element = document.getElementById('qr-print-area')
  html2canvas(element).then((canvas) => {
    canvas.toBlob((blob) => {
      saveAs(blob, `qr-${visitorName}-${date}.jpg`)
      onDownloadComplete()
    })
  })
}

const getWorkplaceDefaultEntryTime = state => {
  return state.profile.company.workplace_default_entry_time ?
    stringHourToJsDate(state.profile.company.workplace_default_entry_time) :
    EIGHT_AM
}

const getWorkplaceDefaultExitTime = state => {
  return state.profile.company.workplace_default_exit_time ?
    stringHourToJsDate(state.profile.company.workplace_default_exit_time) :
    FIVE_PM
}

const isValidLicensePlate = plate => {
  const licensePlatePattern = /^[A-Z0-9-]{1,10}$/;
  return licensePlatePattern.test(plate);
}

const debounce = (func, wait) => {
  let timeout;
  return function (...args) {
    const context = this;
    clearTimeout(timeout);  // Clear the previous timeout
    timeout = setTimeout(() => func.apply(context, args), wait);  // Set a new timeout
  };
};

const getAccessControlQR = async (invitationID, api, { defaultSuccess, defaultCatch, navigate, setAccessControlQR }) => {
  try {
    const response = await api.get(`/invitation/qr/${invitationID}`, { responseType: 'blob' })
    const { data } = await defaultSuccess(response)
    setAccessControlQR(URL.createObjectURL(data))
  } catch (error) {
    defaultCatch(error, navigate, () => getAccessControlQR(invitationID, api, { defaultSuccess, defaultCatch, navigate, setAccessControlQR }))
  }
}

const getVehiclesTypes = t => [t("SMALL"), t("MEDIUM"), t("BIG"), t("BIKE")]

export {
  getVehiclesTypes,
  isValidLicensePlate,
  getWorkplaceDefaultEntryTime,
  getWorkplaceDefaultExitTime,
  getWorkplaceReasons,
  calculateDistanceKM,
  getCurrentLocation,
  getNumberToWeekDay,
  cleanAssignations,
  getCurrentBrowser,
  getParkingReasons,
  getDeskAmenities,
  cleanReservation,
  parseMessageHtml,
  getAmenitieImage,
  getFullNameGuest,
  defaultFunction,
  getLocalitation,
  isReservation,
  isAssignation,
  blobToBase64,
  autoSetPlace,
  evalSetNotes,
  isWorkplace,
  getPosition,
  stopEvent,
  isParking,
  sortItems,
  getPlace,
  isMobile,
  debounce,
  isNear,
  isDesk,
  cleanCafeteriaOrders,
  isCafeteriaOrder,
  downloadTicketAsImage,
  downloadQRAsImage,
  getAccessControlQR
}
