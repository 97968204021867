import React, { useCallback, useEffect, useMemo, useState } from 'react'

import {
  Button,
  IconButton,
  Stack,
  DialogContent,
  useMediaQuery,
  Dialog,
  useTheme,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@mui/material'

import { useTranslation } from 'react-i18next'
import { getVehiclesTypes, isValidLicensePlate } from '../../utilities/utilities'
import MenuItemDefault from '../MenuItemDefault'
import { DEFAULT_VEHICLE } from '../../../variables'
import { connect } from 'react-redux'
import WestIcon from '@mui/icons-material/West';
import { api } from '../../../api/api'
import {
  defaultCatch,
  defaultSuccess,
  setLoading,
  _dispatch as dispatch,
} from '../../../state/actions'
import { Types } from '../../../state/actionTypes'

const defaultFunction = () => { }

function VehicleModal(props) {
  const {
    open = false,
    defaultSuccess,
    defaultCatch,
    dispatch,
    oncloseModal = defaultFunction,
    setOpen = defaultFunction,
    enabledVehiclesDimension = false,
    setLoading,
    vehicles = [],
  } = props

  const theme = useTheme()
  const isLarge = useMediaQuery(theme.breakpoints.up('md'))
  const { t } = useTranslation()

  const types = getVehiclesTypes(t)
  const [messageError, setMessageError] = useState('')
  const [vehicle, setVehicle] = useState(DEFAULT_VEHICLE)

  const evalVehicleError = useCallback(value => {
    setMessageError('')

    if (!isValidLicensePlate(value)) {
      setMessageError(t('This is not a valid vehicle license plate'))
    }

    const repeated_plate = vehicles.filter(_v => _v.license_plate === value).length > 0
    if (repeated_plate) {
      setMessageError(t('You already have a vehicle with that license plate. Enter another.'))
    }
  }, [t, vehicles])

  const onChangeName = useCallback(e => {
    const name = e.target.value
    setVehicle({ ...vehicle, name })
  }, [setVehicle, vehicle])

  const onChangeLicensePlate = useCallback(e => {
    const license_plate = e.target.value.toUpperCase()
    evalVehicleError(license_plate)
    setVehicle({ ...vehicle, license_plate })
  }, [evalVehicleError, setVehicle, vehicle])

  const onChangeDimension = useCallback(e => {
    const vehicle_dimension = e.target.value
    setVehicle({ ...vehicle, vehicle_dimension })
  }, [setVehicle, vehicle])

  const updateVehicle = () => {
    setLoading(true)
    const { id } = vehicle
    const data = { vehicle }
    api.put(`/vehicle/${id}`, data)
      .then(defaultSuccess)
      .then(() => {
        oncloseModal(false)
        dispatch({
          type: Types.SET_SNACKBAR_DATA,
          payload: {
            open: true,
            message: t('vehicle updated successfully'),
            severity: 'success',
            autoHideDuration: 2000,
            horizontal: 'right'
          }
        })
      })
      .catch(defaultCatch)
  }

  const createVehicle = () => {
    setLoading(true)
    api.post(`/vehicle`, { vehicle })
      .then(defaultSuccess)
      .then(() => {
        oncloseModal(false)
        dispatch({
          type: Types.SET_SNACKBAR_DATA,
          payload: {
            open: true,
            message: t('vehicle added successfully'),
            severity: 'success',
            autoHideDuration: 2000,
            horizontal: 'right'
          }
        })
      })
      .catch(defaultCatch)
  }

  const deleteVehicle = () => {
    const { id } = vehicle
    setLoading(true)
    api.delete(`/vehicle/${id}`)
      .then(defaultSuccess)
      .then(({ data = {} }) => {
        const {
          success = false,
          message = t('something went wrong')
        } = data

        oncloseModal(false)
        dispatch({
          type: Types.SET_SNACKBAR_DATA,
          payload: {
            open: true,
            message: success ? t('vehicle deleted successfully') : message,
            severity: success ? 'success' : 'error',
            autoHideDuration: 2000,
            horizontal: 'right'
          }
        })
      })
      .catch(defaultCatch)
  }

  const isEnabled = useMemo(() => {
    const { name, license_plate } = vehicle
    return (
      name &&
      license_plate &&
      !messageError
    )
  }, [messageError, vehicle])

  const closeModal = () => {
    setOpen(false)
    setMessageError('')
  }

  useEffect(() => {
    setVehicle(props.vehicle)
  }, [props.vehicle])

  return (
    <Dialog
      fullScreen={!isLarge}
      open={open}
      onClose={closeModal}>
      <DialogContent style={{ minWidth: '359px' }}>
        <Stack justifyContent="space-between" direction="column" spacing={3}>
          <Stack
            direction="row"
            spacing={0}
            alignItems="center">
            <IconButton
              aria-label="back"
              onClick={() => setOpen(false)}
            >
              <WestIcon />
            </IconButton>
            <h2>
              {vehicle.id > -1 ? t('edit vehicle') : t('add vehicle')}
            </h2>
          </Stack>
          <Stack direction="column" spacing={4} style={{ height: '300px' }} className='PARSO-FORM'>
            <FormControl fullWidth>
              <InputLabel shrink>
                {t('name')}
              </InputLabel>
              <TextField
                value={vehicle.name}
                onChange={onChangeName}
                variant="filled"
                fullWidth
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel shrink>
                {t('license plate')}
              </InputLabel>
              <TextField
                value={vehicle.license_plate}
                onChange={onChangeLicensePlate}
                variant="filled"
                error={messageError.length > 0}
                helperText={messageError}
                fullWidth
              />
            </FormControl>
            {enabledVehiclesDimension &&
              <FormControl fullWidth>
                <InputLabel shrink htmlFor="vehicle-label">
                  {t('type of vehicle')}
                </InputLabel>
                <Select
                  variant="filled"
                  labelId="vehicle-label"
                  value={vehicle.vehicle_dimension}
                  onChange={onChangeDimension}
                >
                  <MenuItemDefault />
                  {types.map((type, index) =>
                    <MenuItem
                      key={index}
                      value={index}>
                      {type}
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            }
          </Stack>
          <Stack spacing={2}>
            {vehicle.id > -1 &&
              <Button
                variant="outlined"
                onClick={deleteVehicle}>
                {t('delete vehicle')}
              </Button>}
            <Button
              disabled={!isEnabled}
              variant="contained"
              onClick={
                vehicle.id > -1 ?
                  () => updateVehicle(vehicle) :
                  () => createVehicle(vehicle)
              }>
              {t('save')}
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}


const mapStateToProps = state => {
  return {
    enabledVehiclesDimension: state.profile.company.enabled_vehicles_dimension
  }
}

const mapDispatchToProps = {
  defaultSuccess,
  defaultCatch,
  setLoading,
  dispatch
}

export default connect(mapStateToProps, mapDispatchToProps)(VehicleModal)