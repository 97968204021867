import React,
{
  useState,
  useCallback,
  useMemo,
  useRef,
  useEffect
} from 'react'

import { connect } from "react-redux"
import { useTranslation } from 'react-i18next'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import FloorMap from '../FloorMap/FloorMap'
import SwipeableCalendar from '../SwipeableCalendar/SwipeableCalendar'
import SuccessBox from '../SuccessBox/SuccessBox'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { useNavigate } from 'react-router-dom'
import { api } from '../../../api/api'
import { Types } from '../../../state/actionTypes'
import {
  _dispatch as dispatch,
  setLoading,
  defaultSuccess,
  defaultCatch,
} from '../../../state/actions'


import PickersDay from '@mui/lab/PickersDay'

import {
  IconButton,
  Button,
  Badge,
  Stack,
  DialogContent,
  Dialog,
  Divider,
  TextField,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Grid,
  useMediaQuery,
  useTheme
} from '@mui/material'

import {
  Groups as GroupsIcon,
  LocationCity as LocationCityIcon,
  AirlineSeatReclineNormal as AirlineSeatReclineNormalIcon,
  WatchLater as WatchLaterIcon,
  Close as CloseIcon,
  Event as EventIcon,
  AccessTime as AccessTimeIcon,
  PlaceOutlined as PlaceOutlinedIcon,
  CheckCircle as CheckCircleIcon,
  InfoOutlined as InfoOutlinedIcon,
  AlternateEmail,
  Info as InfoIcon,
  Link as LinkIcon,
  DirectionsCar,
  Notes,
  Place
} from '@mui/icons-material'

import DeskDot from '../Svgs/DeskDot'
import LocationCircle from '../Svgs/LocationCircle'

import {
  formatDate,
  formatDateToMed,
  formatDateISOShort,
  formatDateToISOShort,
  formatDateToTimeISO,
  formatTimeFromJS,
  isTodayFromJsDate,
  getTime,
  getCurrentHour,
  formatDateMedMed,
  formatTime
} from '../../utilities/FormatDate'

import {
  autoSetPlace,
  evalSetNotes,
  getDeskAmenities,
  getLocalitation,
  getWorkplaceDefaultEntryTime,
  getWorkplaceDefaultExitTime,
  getWorkplaceReasons,
  sortItems
} from '../../utilities/utilities'

import {
  errorCreateReservationWorkplaceI18n,
  reasonI18n
} from '../../utilities/Dictionary'

import SwipeableTimePicker from '../SwipeableTimePicker/SwipeableTimePicker'

import {
  DEFAULT_OFFSET_MIN_RESERVATION_MINUTES,
  DESK_RESERVATION,
  ROOM_RESERVATION,
  PARKING_DESK_RESERVATION,
  PARKING_ROOM_RESERVATION,
  EIGHT_AM,
  FIVE_PM,
  END_DAY,
  FORM,
  SUCCESS,
  ENABLED,
  ENABLED_PD,
  ENABLED_PR
} from '../../../variables'

import MenuItemDefault from '../MenuItemDefault'
import RoomsModal from '../RoomsModal/RoomsModal'
import CustomWorkplaceIcons from '../../utilities/CustomWorkplaceIcons'
import './WorkplaceReservation.sass'
import MessageBox from '../MessageBox/MessageBox'
import EmailAutoComplete from '../EmailAutoComplete/EmailAutoComplete'

const iconSx = { color: '#8C8CA1', fontSize: 15 }
const iconSelected = { color: '#1D8099', fontSize: 14 }
const today = new Date()

function WorkplaceReservation(props) {
  const {
    sites,
    dispatch,
    setLoading,
    selectedType,
    defaultCatch,
    defaultSuccess,
    minReservationTime,
    workplaceDefaultExitTime,
    workplaceDefaultEntryTime,
    unformattedDefaultEntryTime,
    unformattedDefaultExitTime,
    askForRoomReservationNotes,
    askForRoomReservationReason,
    askForDeskReservationReason,
    askForDeskReservationEntryTime,
    maxDate,
    loading,
    workplaceCustomLabel,
    workplaceCustomIconIndex,
    enabledWorkplaceCustomIcon,
    enabledWorkplaceRoomReservationsAttendees,
    setMainStepperActiveStep,
    parkingReservationData,
    askForParkingReservationReason,
    askForParkingReservationVehicle,
    askForParkingReservationEntryTime,
    reservationsAndAssignations,
    enabledPD,
    enabledPR
  } = props

  const { t } = useTranslation()
  const theme = useTheme()
  const isLarge = useMediaQuery(theme.breakpoints.up('md'))
  const initDate = props.initDate || today

  const [activeStep, setActiveStep] = useState(FORM)
  const [date, setDate] = useState(initDate)

  const getDefaultEntryTime = useCallback(date => {
    const isTodayValue = isTodayFromJsDate(date)
    if (workplaceDefaultEntryTime && !isTodayValue)
      return workplaceDefaultEntryTime

    if (isTodayValue) {
      const minutes = minReservationTime + DEFAULT_OFFSET_MIN_RESERVATION_MINUTES
      const minHour = getCurrentHour(0, minutes)
      return workplaceDefaultEntryTime &&
        workplaceDefaultEntryTime >= minHour ?
        workplaceDefaultEntryTime :
        minHour
    }
    return EIGHT_AM;
  }, [workplaceDefaultEntryTime, minReservationTime])


  const DEFAULT_MARKER = useMemo(() => ({ id: -1, name: t('select'), amenities: [] }), [t])
  const DEFAULT_ROOM = useMemo(() => ({ id: -1, name: t('select') }), [t])

  const entry = getDefaultEntryTime(date)
  const [timePickerValue, setTimePickerValue] = useState(entry)
  const [entry_time, setEntryTime] = useState(entry)

  const getDefaultExitTime = useCallback(entry => {
    if (!askForDeskReservationEntryTime) return END_DAY
    if (workplaceDefaultExitTime && workplaceDefaultExitTime > entry) return workplaceDefaultExitTime
    return END_DAY
  }, [askForDeskReservationEntryTime, workplaceDefaultExitTime])

  const exit = getDefaultExitTime(entry_time)
  const [exitTimePickerValue, setExitTimePickerValue] = useState(exit)
  const [exit_time, setExitTime] = useState(exit)

  const navigate = useNavigate()
  const inputDateRef = useRef()
  const inputHourRef = useRef()
  const selectSiteRef = useRef()
  const workplaceReasons = useMemo(() => getWorkplaceReasons(t), [t])


  // Workplace reservation
  const [buildings, setBuildings] = useState([])
  const [floors, setFloors] = useState([])
  const [site, setSite] = useState(-1)
  const [building, setBuilding] = useState(-1)
  const [floor, setFloor] = useState(-1)
  const [desk, setDesk] = useState(DEFAULT_MARKER)
  const [selectedMarker, setSelectedMarker] = useState(DEFAULT_MARKER)
  const [allTime, setAllTime] = useState(false)
  const [allTimeValue, setAllTimeValue] = useState(false)
  const [workplaceReason, setWorkplaceReason] = useState(workplaceReasons[selectedType === DESK_RESERVATION ? 1 : 0])
  const [markers, setMarkers] = useState([])
  const [floorImage, setFloorImage] = useState({})
  const [openMap, setOpenMap] = useState(false)
  const [openRoomsList, setOpenRoomsList] = useState(false)
  const [availableSites, setAvailableSites] = useState(sites)
  const [floorMapId, setFloorMapId] = useState()

  // Room reservation
  const [room, setRoom] = useState(DEFAULT_ROOM)
  const [rooms, setRooms] = useState([])
  const [doYouWantToEdit, setDoYouWantToEdit] = useState(false)
  const [openTimePicker, setOpenTimePicker] = useState(false)
  const [openCalendar, setOpenCalendar] = useState(false)
  const [calendarDate, setCalendarDate] = useState(initDate)
  const [maximumOccupancyFloor, setMaximumOccupancyFloor] = useState(false)
  const [notes, setNotes] = useState('')
  const [roomAttendees, setRoomAttendees] = useState([])
  const [availableAmenities, setAvailableAmenities] = useState([])
  const [successItems, setSuccessItems] = useState([])

  useEffect(() => {
    if (selectedType === ROOM_RESERVATION || selectedType === PARKING_ROOM_RESERVATION) {
      fetchAvailableRooms(calendarDate)
    }
    if (selectedType === DESK_RESERVATION || selectedType === PARKING_DESK_RESERVATION) {
      fetchSitesWithDate(date)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchFloorsImage = useCallback(params => {
    const { id, width, height } = params
    setLoading(true)
    api.get(`/workplace/floor_map/serve/${id}`, { responseType: "blob" })
      .then(defaultSuccess)
      .then(({ data }) => {
        const blob = URL.createObjectURL(data)
        setFloorImage({ url: blob, size: [width, height] })
      })
      .catch(error => defaultCatch(error, navigate, () => fetchFloorsImage(params)))
  }, [defaultCatch, defaultSuccess, navigate, setLoading])

  const setWorkplaceData = useCallback(({ floor_map }) => {
    evalMarkerOccupancy(floor_map.desks)
    setMarkers(floor_map.desks)
    fetchFloorsImage(floor_map)
  }, [fetchFloorsImage])

  const fetchFloorMap = useCallback((floorMapId, date) => {
    if (!floorMapId) return

    setLoading(true)
    let start_hour = entry_time
    let end_hour = exit_time

    if (allTime) {
      start_hour = getDefaultEntryTime(date)
      end_hour = getDefaultExitTime(start_hour)
    }

    const params = {
      date: formatDateToISOShort(date),
      start_hour: formatDateToTimeISO(start_hour),
      end_hour: formatDateToTimeISO(end_hour)
    }

    api.get(`/workplace/floor_map/${floorMapId}`, { params })
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success) return
        if (selectedType === DESK_RESERVATION) setWorkplaceData(data)
      })
      .catch(error => defaultCatch(error, navigate, () => fetchFloorMap(floor)))
  }, [allTime, defaultCatch, defaultSuccess, entry_time, exit_time, floor, getDefaultEntryTime, getDefaultExitTime, navigate, selectedType, setLoading, setWorkplaceData])


  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => fetchFloorMap(floorMapId, date), [floorMapId, date])

  const cleanRooms = useCallback((rooms = []) => {
    // Remove DISABLED rooms
    rooms = rooms.filter(room => room.status === ENABLED)

    // Remove repeat elements
    const items = []
    const ids = []
    rooms.forEach(r => {
      if (!ids.includes(r.id)) {
        ids.push(r.id)
        items.push(r)
      }
    })
    return items
  }, [])

  const fetchAvailableRooms = useCallback(calendarDate => {
    setLoading(true)
    const _date = formatDateToISOShort(calendarDate)
    const params = { date: _date, all: true }
    api.get(`workplace/rooms/availability`, { params })
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success) return
        const rooms = cleanRooms(data.rooms)
        setRooms(rooms)
        setAvailableAmenities(data.amenities)
      })
      .catch(error => defaultCatch(error, navigate, fetchAvailableRooms))
  }, [setLoading, defaultSuccess, cleanRooms, defaultCatch, navigate])

  const evalMarkerOccupancy = markers => {
    if (!markers.length) return
    const occupiedMarkers = markers.filter(m => m.occupied)
    setMaximumOccupancyFloor(occupiedMarkers.length === markers.length)
  }

  const getFormateTimeWorkplace = useCallback(() => {
    if (allTime) return t('all day')
    if (entry_time && exit_time) {
      return `${formatTimeFromJS(entry_time)} - ${formatTimeFromJS(exit_time)}`
    }
    return t('select')
  }, [allTime, entry_time, exit_time, t])

  const getPlaceByID = useCallback(item => {
    const { site, building, floor } = item
    const nameSite = sites.find(s => s.id === site).name
    const nameBuilding = buildings.find(b => b.id === building).name
    const nameFloor = floors.find(f => f.id === floor).name

    return `${nameSite}, ${nameBuilding}, ${nameFloor}`
  }, [buildings, floors, sites])

  const isEnabledDesk = useCallback(() => {
    return (
      date &&
      entry_time &&
      exit_time &&
      site > -1 &&
      building > -1 &&
      floor > -1 &&
      desk.id > -1
    )
  }, [building, date, desk, exit_time, entry_time, floor, site])

  const isEnabledRoom = useCallback(() => {
    return (
      date &&
      (allTime || (entry_time && exit_time)) &&
      room.id > -1
    )
  }, [entry_time, exit_time, date, allTime, room])

  const badgeOnclick = useCallback(day => {
    if (day < today) return

    if (selectedType === DESK_RESERVATION) {
      if (day >= maxDate) return
    }

    setCalendarDate(day)
  }, [maxDate, selectedType])

  const resetTimePicker = useCallback(() => {
    setTimePickerValue(entry_time)
    setExitTimePickerValue(exit_time)
    setAllTimeValue(allTime)
  }, [allTime, entry_time, exit_time])

  const reloadDefaultTimePicker = useCallback(date => {
    const entry = getDefaultEntryTime(date)
    setTimePickerValue(entry)
    setEntryTime(entry)

    const exit = getDefaultExitTime(entry)
    setExitTimePickerValue(exit)
    setExitTime(exit)
  }, [getDefaultEntryTime, getDefaultExitTime])

  const fetchSitesWithDate = useCallback((selectedDate) => {
    setLoading(true)
    let start_hour = entry_time
    let end_hour = exit_time

    if (allTime) {
      start_hour = getDefaultEntryTime(selectedDate)
      end_hour = getDefaultExitTime(start_hour)
    }

    const params = {
      date: formatDateToISOShort(selectedDate),
      start_hour: formatDateToTimeISO(start_hour),
      end_hour: formatDateToTimeISO(end_hour)
    }

    api.get('/workplace/sites', { params })
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success) return
        setAvailableSites(sortItems(data.sites))
        autoSetPlace(data.sites, {
          setBuildings,
          setBuilding,
          setFloors,
          setFloor,
          setFloorMapId
        })
      })
      .catch(error => defaultCatch(error, navigate, () => fetchSitesWithDate(selectedDate)))
      .finally(() => setLoading(false))
  }, [allTime, entry_time, exit_time, getDefaultEntryTime, getDefaultExitTime, defaultSuccess, defaultCatch, navigate, setLoading])

  // Modificar onContinueWorkplace para usar la nueva función
  const onContinueWorkplace = useCallback(() => {
    setOpenCalendar(false)
    setDate(calendarDate)
    reloadDefaultTimePicker(calendarDate)
    setFloor(-1)
    setDesk(DEFAULT_MARKER)
    fetchSitesWithDate(calendarDate)
  }, [calendarDate, reloadDefaultTimePicker, fetchSitesWithDate, DEFAULT_MARKER])

  const onContinueRoomAvailability = useCallback(() => {
    setOpenCalendar(false)
    setDate(calendarDate)
    reloadDefaultTimePicker(calendarDate)
    fetchAvailableRooms(calendarDate)
  }, [calendarDate, fetchAvailableRooms, reloadDefaultTimePicker])

  const onCloseCalendar = useCallback(() => {
    setCalendarDate(date)
    setOpenCalendar(false)
  }, [date])

  const onContinueTimePicker = useCallback(() => {
    setAllTime(allTimeValue)
    setEntryTime(timePickerValue)
    setExitTime(exitTimePickerValue)
    setFloor(-1)
    setDesk(DEFAULT_MARKER)
    setOpenTimePicker(false)
  }, [allTimeValue, timePickerValue, exitTimePickerValue, DEFAULT_MARKER])

  const onCloseTimePicker = useCallback(() => {
    setOpenTimePicker(false)
    resetTimePicker()
  }, [resetTimePicker])

  const getFormateDate = useCallback(() => {
    if (date) return formatDateToMed(date)
    return t('select')
  }, [date, t])

  const EntryTimeIsLaterEqualThanExitTime = useCallback(
    () => {
      if (!timePickerValue || !exitTimePickerValue) return false
      const entry = formatDateToTimeISO(timePickerValue)
      const exit = formatDateToTimeISO(exitTimePickerValue)
      return (entry >= exit)
    },
    [exitTimePickerValue, timePickerValue]
  )

  const disabledTimePickerWorplace = useCallback(
    () => EntryTimeIsLaterEqualThanExitTime(),
    [EntryTimeIsLaterEqualThanExitTime]
  )

  const renderTimePickerMessageWorkplace = useCallback(() => {
    if (EntryTimeIsLaterEqualThanExitTime()) {
      return (
        <MessageBox
          message={t('Check out time must be greater than check in time')}
          type='error'
        />
      )
    }
    return <p className='message'></p>
  }, [EntryTimeIsLaterEqualThanExitTime, t])

  const onChangeCalendarDateWorkplace = useCallback(date => {
    setCalendarDate(date)
  }, [])

  const resetMarker = useCallback(() => {
    setDesk(DEFAULT_MARKER)
    setSelectedMarker(DEFAULT_MARKER)
  }, [DEFAULT_MARKER])

  // Modificar onChangeSite para usar availableSites
  const onChangeSite = useCallback(e => {
    const siteId = e.target.value
    setSite(siteId)
    setBuilding(-1)
    setFloor(-1)
    setDesk(DEFAULT_MARKER)

    if (siteId !== -1) {
      fetchSitesWithDate(date)
      const site = availableSites.find(s => s.id === siteId)
      if (site) {
        setBuildings(site.buildings)
      }
    } else {
      setBuildings([])
    }
  }, [availableSites, DEFAULT_MARKER, date, fetchSitesWithDate])

  const onChangeBuilding = useCallback(event => {
    const building = event.target.value
    const floors = buildings.find(b => b.id === building).floors || []
    setBuilding(building)
    setFloors(floors)
    resetMarker()

    if (floors.length === 1) {
      const floorItem = floors[0]
      setFloor(floorItem.id)
      setFloorMapId(floorItem.floor_map.id)
    }
  }, [buildings, resetMarker])

  const onChangeFloor = useCallback(event => {
    const floor = event.target.value
    const floorItem = floors.find(f => f.id === floor)
    setFloor(floor)
    setFloorMapId(floorItem.floor_map.id)
    resetMarker()
  }, [floors, resetMarker])

  const onChangeWorkplaceReason = event => {
    const workplaceReason = event.target.value
    setWorkplaceReason(workplaceReason)
  }

  const onChangeDesk = useCallback(() => {
    if (floor === -1) return
    setLoading(true)
    setOpenMap(true)
  }, [floor, setLoading])

  const onChangeRoom = () => {
    setOpenRoomsList(true)
  }

  const renderDay = useCallback((day, _value, DayComponentProps) => {
    const ISODate = formatDateToISOShort(day)
    const isToday = ISODate === formatDateToISOShort(_value[0])

    return (
      <Badge
        className={`badge-calendar ${isToday ? 'today' : ''}`}
        key={day.toString()}
        onClick={() => badgeOnclick(day)}
      >
        <PickersDay
          {...DayComponentProps}
        />
      </Badge>
    );
  }, [badgeOnclick])

  const onChangeDateWorkplace = useCallback(() => {
    setOpenCalendar(true)
  }, [])

  const onChangeTimeWorkplace = useCallback(() => {
    if (!date) return
    setOpenTimePicker(true)
  }, [date])

  const getMinTime = useCallback(() => {
    if (!date) return
    const _date = formatDate(date)
    const _today = formatDate(today)
    if (_date === _today) return today
  }, [date])

  const findExistingParkingReservation = useCallback((selectedDate) => {
    const parkingReservation = reservationsAndAssignations?.find(
      reservation =>
        reservation.type === "ParkingReservation" &&
        reservation.reservation_date === formatDateToISOShort(selectedDate)
    )

    return parkingReservation?.id || null
  }, [reservationsAndAssignations])

  const updateExistingWorkplaceReservations = useCallback(async (parkingId) => {
    try {
      const existingReservations = reservationsAndAssignations.filter(reservation => {
        const isWorkplaceReservation =
          reservation.type === DESK_RESERVATION ||
          reservation.type === ROOM_RESERVATION

        const dateMatches = reservation.reservation_date === formatDateToISOShort(date)

        return isWorkplaceReservation && dateMatches
      })

      for (const reservation of existingReservations) {
        const type = reservation.type === DESK_RESERVATION ? 'desk_reservation' : 'room_reservation'

        const baseData = {
          entry_time: reservation.entry_time,
          exit_time: reservation.exit_time,
          parkeable_id: parkingId
        }

        if (type === 'room_reservation') {
          baseData.notes = reservation.notes || ''
          baseData.attendees = reservation.attendees || []
        } else {
          baseData.reason = reservation.reason || ''
        }

        const data = {
          [type]: baseData
        }

        await api.put(`/workplace/${type}/${reservation.id}`, data)
      }
    } catch (error) {
      console.error('Error en updateExistingWorkplaceReservations:', error)
      defaultCatch(error, navigate, () => updateExistingWorkplaceReservations(parkingId))
    }
  }, [reservationsAndAssignations, date, defaultCatch, navigate])

  const saveReservation = useCallback(() => {
    setLoading(true)
    let _entry_time = entry_time
    let _exit_time = exit_time

    if (allTime) {
      _entry_time = getDefaultEntryTime(date)
      _exit_time = getDefaultExitTime(_entry_time)
    }

    if (parkingReservationData) {
      api.post('/parking_reservations/multiples', parkingReservationData)
        .then(response => {
          const { data: parkingData } = response
          if (!parkingData.success && (parkingData.messages || parkingData.message)) {
            throw new Error(parkingData.messages?.[0] || parkingData.message)
          }

          const parkingId = parkingData.parking_reservations[0]?.id

          return updateExistingWorkplaceReservations(parkingId)
            .then(() => {
              const isRoomReservation = selectedType === ROOM_RESERVATION || selectedType === PARKING_ROOM_RESERVATION
              const isDeskReservation = selectedType === DESK_RESERVATION || selectedType === PARKING_DESK_RESERVATION

              const workplacePayload = isRoomReservation ? {
                room_reservation: {
                  reservation_date: formatDateToISOShort(date),
                  room_id: room.id,
                  notes,
                  entry_time: formatDateToTimeISO(_entry_time),
                  exit_time: formatDateToTimeISO(_exit_time),
                  attendees: roomAttendees,
                  parkeable_id: parkingId
                }
              } : isDeskReservation ? {
                desk_reservation: {
                  reservation_date: formatDateToISOShort(date),
                  desk_id: desk.id,
                  reason: workplaceReason,
                  entry_time: formatDateToTimeISO(_entry_time),
                  exit_time: formatDateToTimeISO(_exit_time),
                  parkeable_id: parkingId
                }
              } : null

              return api.post(
                isRoomReservation ? '/workplace/room_reservation' : '/workplace/desk_reservation',
                workplacePayload
              ).then(workplaceResponse => {
                return { parkingData, workplaceResponse: workplaceResponse.data }
              })
            })
        })
        .then(({ parkingData, workplaceResponse }) => {
          if (!workplaceResponse.success && workplaceResponse.message) {
            throw new Error(workplaceResponse.message)
          }

          let successItemsList = []

          const { parking_reservations = [] } = parkingData
          successItemsList = parking_reservations.map(reservation => ({
            ...reservation,
            type: 'parking'
          }))

          const isRoomReservation = selectedType === ROOM_RESERVATION || selectedType === PARKING_ROOM_RESERVATION
          if (isRoomReservation) {
            successItemsList.push({
              ...workplaceResponse.room_reservation,
              type: 'room'
            })
          } else {
            successItemsList.push({
              ...workplaceResponse.desk_reservation,
              site,
              building,
              floor,
              desk,
              type: 'desk'
            })
          }

          setSuccessItems(successItemsList)
          setActiveStep(SUCCESS)
          setMainStepperActiveStep(2)

          dispatch({
            type: Types.CLEAR_PARKING_RESERVATION_DATA
          })
        })
        .catch(error => {
          console.error('Error en el flujo de reserva:', error)
          dispatch({
            type: Types.SET_SNACKBAR_DATA,
            payload: {
              open: true,
              message: errorCreateReservationWorkplaceI18n(error.message),
              severity: 'error',
              autoHideDuration: 6000
            }
          })
          defaultCatch(error, navigate, saveReservation)
        })
        .finally(() => setLoading(false))
    } else {
      const isRoomReservation = selectedType === ROOM_RESERVATION || selectedType === PARKING_ROOM_RESERVATION
      const existingParkingId = (enabledPD || enabledPR) ? findExistingParkingReservation(date) : null

      const workplacePayload = isRoomReservation ? {
        room_reservation: {
          reservation_date: formatDateToISOShort(date),
          room_id: room.id,
          notes,
          entry_time: formatDateToTimeISO(_entry_time),
          exit_time: formatDateToTimeISO(_exit_time),
          attendees: roomAttendees,
          ...(existingParkingId && { parkeable_id: existingParkingId })
        }
      } : {
        desk_reservation: {
          reservation_date: formatDateToISOShort(date),
          desk_id: desk.id,
          reason: workplaceReason,
          entry_time: formatDateToTimeISO(_entry_time),
          exit_time: formatDateToTimeISO(_exit_time),
          ...(existingParkingId && { parkeable_id: existingParkingId })
        }
      }

      api.post(
        isRoomReservation ? '/workplace/room_reservation' : '/workplace/desk_reservation',
        workplacePayload
      )
        .then(response => {
          const { data } = response
          if (!data.success && data.message) {
            throw new Error(data.message)
          }

          const successItemsList = []
          if (isRoomReservation) {
            successItemsList.push({
              ...data.room_reservation,
              type: 'room'
            })
          } else {
            successItemsList.push({
              ...data.desk_reservation,
              site,
              building,
              floor,
              desk,
              type: 'desk'
            })
          }

          setSuccessItems(successItemsList)
          setActiveStep(SUCCESS)
          setMainStepperActiveStep(2)
        })
        .catch(error => {
          dispatch({
            type: Types.SET_SNACKBAR_DATA,
            payload: {
              open: true,
              message: errorCreateReservationWorkplaceI18n(error.message),
              severity: 'error',
              autoHideDuration: 6000
            }
          })
          defaultCatch(error, navigate, saveReservation)
        })
        .finally(() => setLoading(false))
    }
  }, [
    entry_time, exit_time, allTime, date, getDefaultEntryTime, getDefaultExitTime,
    parkingReservationData, selectedType, room, notes, roomAttendees,
    desk, workplaceReason, site, building, floor,
    dispatch, navigate, setLoading, setSuccessItems, setActiveStep, setMainStepperActiveStep,
    defaultCatch, enabledPD, enabledPR, findExistingParkingReservation, updateExistingWorkplaceReservations
  ])

  const slide1Workplace = useCallback(() => {
    return (
      <>
        <div className="WorkplaceReservation div-reservation">
          <h2>{workplaceCustomLabel ? workplaceCustomLabel : t('workplace reservation')}</h2>
          <div className='form-wrapper workplace'>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Grid container spacing={isLarge ? 8 : 5} alignItems="flex-end">
                <Grid item xs={12} md={7}>
                  <label
                    className={`pre-label-form`}>
                    {t('when do you plan to use the space?')}
                  </label>
                  <Grid
                    container
                    spacing={isLarge ? 0 : 5}
                    className="form-input-group"
                  >
                    <Grid item xs={12} md>
                      <FormControl fullWidth>
                        <InputLabel shrink>
                          {t('date')}
                        </InputLabel>
                        <TextField
                          value={getFormateDate()}
                          onClick={onChangeDateWorkplace}
                          variant="filled"
                          fullWidth
                          inputRef={inputDateRef}
                        />
                      </FormControl>
                    </Grid>
                    {askForDeskReservationEntryTime &&
                      <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                      />
                    }
                    {askForDeskReservationEntryTime &&
                      <Grid item xs={12} md>
                        <FormControl fullWidth>
                          <InputLabel shrink>
                            {t('hour')}
                          </InputLabel>
                          <TextField
                            value={getFormateTimeWorkplace()}
                            onClick={onChangeTimeWorkplace}
                            disabled={!date}
                            variant="filled"
                            fullWidth
                            inputRef={inputHourRef}
                          />
                        </FormControl>
                      </Grid>
                    }
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <label
                    className={`pre-label-form`}>
                    {t('Where would you like to book?')}
                  </label>
                  <Grid
                    container
                    spacing={isLarge ? 0 : 5}
                    className="form-input-group"
                  >
                    <Grid item xs={12} md>
                      <FormControl fullWidth>
                        <InputLabel shrink>
                          {t('site')}
                        </InputLabel>
                        <Select
                          ref={selectSiteRef}
                          disabled={!date}
                          variant="filled"
                          labelId="site-label"
                          value={site}
                          onChange={onChangeSite}
                        >
                          <MenuItemDefault />
                          {availableSites.map(s =>
                            <MenuItem
                              key={s.id}
                              value={s.id}>
                              {s.name}
                            </MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Divider orientation="vertical" variant="middle" flexItem />
                    <Grid item xs={12} md>
                      <FormControl fullWidth>
                        <InputLabel shrink>
                          {t('building')}
                        </InputLabel>
                        <Select
                          disabled={site === -1}
                          variant="filled"
                          labelId="building-label"
                          value={site !== -1 ? building : t('select')}
                          placeholder={t('select')}
                          onChange={onChangeBuilding}
                        >
                          <MenuItemDefault />
                          {buildings.map(b =>
                            <MenuItem
                              key={b.id}
                              value={b.id}>
                              {b.name}
                            </MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Divider orientation="vertical" variant="middle" flexItem />
                    <Grid item xs={12} md>
                      <FormControl fullWidth>
                        <InputLabel shrink>
                          {t('floor')}
                        </InputLabel>
                        <Select
                          disabled={site === -1 || building === -1}
                          variant="filled"
                          labelId="floor-label"
                          value={site !== -1 ? floor : t('select')}
                          placeholder={t('select')}
                          onChange={onChangeFloor}
                        >
                          <MenuItemDefault />
                          {floors.map(f =>
                            <MenuItem
                              key={f.id}
                              value={f.id}
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                gap: '8px'
                              }}
                            >
                              {f.name} - {f.availables} {f.availables === 1 ? t('available') : t('availables')}
                            </MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Divider orientation="vertical" variant="middle" flexItem />
                    <Grid item xs={12} md>
                      <FormControl fullWidth>
                        <InputLabel shrink>
                          {workplaceCustomLabel ? workplaceCustomLabel : t('desk')}
                        </InputLabel>
                        <TextField
                          disabled={site === -1 || floor === -1}
                          value={desk.name}
                          onClick={onChangeDesk}
                          placeholder={t('select')}
                          variant="filled"
                          autoComplete="off"
                          fullWidth
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>

                {askForDeskReservationReason &&
                  <Grid item xs={12} md={7}>
                    <label
                      className={`pre-label-form`}>
                      {t('what is your reason for attendance?')}
                    </label>
                    <Grid
                      container
                      spacing={isLarge ? 0 : 5}
                      className="form-input-group"
                    >
                      <Grid item xs={12} md>
                        <FormControl fullWidth>
                          <InputLabel shrink htmlFor="reason-label">
                            {t('reason')}
                          </InputLabel>
                          <Select
                            disabled={!desk}
                            variant="filled"
                            labelId="reason-label"
                            value={workplaceReason}
                            onChange={onChangeWorkplaceReason}
                          >
                            <MenuItemDefault />
                            {workplaceReasons.map((r, index) =>
                              <MenuItem
                                key={index}
                                value={r}>
                                {r}
                              </MenuItem>
                            )}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                }
              </Grid>
            </LocalizationProvider>
          </div>
          <div className='new-reservation-footer'>
            <Button
              fullWidth={!isLarge}
              variant={!isEnabledDesk() ? "outlined" : "contained"}
              disabled={!isEnabledDesk()}
              onClick={saveReservation} >
              {isLarge ?
                t('ACCEPT RESERVATION') :
                t('ACCEPT')
              }
            </Button>
          </div>
        </div>
        <SwipeableCalendar
          maxDate={maxDate}
          minDate={today}
          date={calendarDate}
          open={openCalendar}
          onChange={onChangeCalendarDateWorkplace}
          setOpen={setOpenCalendar}
          renderDay={renderDay}
          onContinue={onContinueWorkplace}
          onClose={onCloseCalendar}
        />
        <SwipeableTimePicker
          type="range"
          allTime={allTimeValue}
          time={timePickerValue}
          exitTime={exitTimePickerValue}
          open={openTimePicker}
          setAllTime={setAllTimeValue}
          setTime={setTimePickerValue}
          setExitTime={setExitTimePickerValue}
          minTime={getMinTime()}
          setOpen={setOpenTimePicker}
          onContinue={onContinueTimePicker}
          onClose={onCloseTimePicker}
          isDisabled={disabledTimePickerWorplace()}
          renderMessage={renderTimePickerMessageWorkplace}
        />
      </>
    )
  }, [
    allTimeValue,
    askForDeskReservationEntryTime,
    askForDeskReservationReason,
    building,
    buildings,
    calendarDate,
    date,
    desk,
    disabledTimePickerWorplace,
    exitTimePickerValue,
    floor,
    floors,
    getFormateDate,
    getFormateTimeWorkplace,
    maxDate,
    getMinTime,
    isEnabledDesk,
    isLarge,
    onChangeBuilding,
    onChangeCalendarDateWorkplace,
    onChangeDateWorkplace,
    onChangeDesk,
    onChangeFloor,
    onChangeSite,
    onChangeTimeWorkplace,
    onCloseCalendar,
    onCloseTimePicker,
    onContinueTimePicker,
    onContinueWorkplace,
    openCalendar,
    openTimePicker,
    renderDay,
    renderTimePickerMessageWorkplace,
    saveReservation,
    site,
    t,
    timePickerValue,
    workplaceReason,
    workplaceReasons,
    workplaceCustomLabel,
    availableSites
  ])

  const slide1Room = useCallback(() => {

    return (
      <>
        <div className="div-reservation">
          <h2>{t('room reservation')}</h2>
          <div className='form-wrapper workplace'>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Grid container spacing={isLarge ? 8 : 5} alignItems="flex-end">
                <Grid item xs={12} md={7}>
                  <label
                    className={`pre-label-form`}>
                    {t('When do you plan to use the room?')}
                  </label>
                  <Grid
                    container
                    spacing={isLarge ? 0 : 5}
                    className="form-input-group"
                  >
                    <Grid item xs={12} md>
                      <FormControl fullWidth>
                        <TextField
                          value={getFormateDate()}
                          onClick={onChangeDateWorkplace}
                          variant="filled"
                          fullWidth
                          inputRef={inputDateRef}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={7}>
                  <label
                    className={`pre-label-form`}>
                    {t('What room do you want to reserve?')}
                  </label>
                  <Grid item xs={12} md>
                    <FormControl fullWidth>
                      <TextField
                        value={room.name}
                        onClick={onChangeRoom}
                        placeholder={t('select')}
                        variant="filled"
                        autoComplete="off"
                        fullWidth
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={7}>
                  <label
                    className={`pre-label-form`}>
                    {t('At what time would you like to book?')}
                  </label>
                  <Grid item xs={12} md>
                    <FormControl fullWidth>
                      <TextField
                        value={getFormateTimeWorkplace()}
                        variant="filled"
                        fullWidth
                        inputRef={inputHourRef}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                {askForRoomReservationReason &&
                  <Grid item xs={12} md={7}>
                    <label
                      className={`pre-label-form`}>
                      {t('what is your reason for attendance?')}
                    </label>
                    <Grid
                      container
                      spacing={isLarge ? 0 : 5}
                      className="form-input-group"
                    >
                      <Grid item xs={12} md>
                        <FormControl fullWidth>
                          <Select
                            disabled={!desk}
                            variant="filled"
                            labelId="reason-label"
                            value={workplaceReason}
                            onChange={onChangeWorkplaceReason}
                          >
                            <MenuItemDefault />
                            {workplaceReasons.map((r, index) =>
                              <MenuItem
                                key={index}
                                value={r}>
                                {r}
                              </MenuItem>
                            )}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                }
                {askForRoomReservationNotes &&
                  <Grid item xs={12} md={7}>
                    <label
                      className={`pre-label-form`}>
                      {t('Notes (optional)')}
                    </label>
                    <Grid
                      container
                      spacing={isLarge ? 0 : 5}
                      className="form-input-group"
                    >
                      <Grid item xs={12} md>
                        <FormControl fullWidth>
                          <TextField
                            placeholder={isLarge ? '' : t('Notes (optional)')}
                            value={notes}
                            onChange={e => evalSetNotes(e.target.value, setNotes)}
                            variant="filled"
                            autoComplete="off"
                            rows={3}
                            fullWidth
                            multiline
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                }
                {enabledWorkplaceRoomReservationsAttendees &&
                  <EmailAutoComplete
                    emails={roomAttendees}
                    setEmails={setRoomAttendees}
                  />
                }
              </Grid>
            </LocalizationProvider>
          </div>
          <div className='new-reservation-footer'>
            <Button
              variant={!isEnabledRoom() ? "outlined" : "contained"}
              disabled={!isEnabledRoom()}
              onClick={saveReservation}
              fullWidth
            >
              {isLarge ?
                t('ACCEPT RESERVATION') :
                t('ACCEPT')
              }
            </Button>
          </div>
        </div>
        <SwipeableCalendar
          maxDate={maxDate}
          minDate={today}
          date={calendarDate}
          open={openCalendar}
          onChange={onChangeCalendarDateWorkplace}
          setOpen={setOpenCalendar}
          renderDay={renderDay}
          onContinue={onContinueRoomAvailability}
          onClose={onCloseCalendar}
        />
        <SwipeableTimePicker
          type="range"
          allTime={allTimeValue}
          time={timePickerValue}
          exitTime={exitTimePickerValue}
          open={openTimePicker}
          setAllTime={setAllTimeValue}
          setTime={setTimePickerValue}
          setExitTime={setExitTimePickerValue}
          minTime={getMinTime()}
          setOpen={setOpenTimePicker}
          onContinue={onContinueTimePicker}
          onClose={onCloseTimePicker}
          isDisabled={disabledTimePickerWorplace()}
          renderMessage={renderTimePickerMessageWorkplace}
        />
      </>
    )
  }, [t, isLarge, getFormateDate, onChangeDateWorkplace, room, getFormateTimeWorkplace, askForRoomReservationReason, desk, workplaceReason, workplaceReasons, askForRoomReservationNotes, notes, enabledWorkplaceRoomReservationsAttendees, roomAttendees, isEnabledRoom, saveReservation, maxDate, calendarDate, openCalendar, onChangeCalendarDateWorkplace, renderDay, onContinueRoomAvailability, onCloseCalendar, allTimeValue, timePickerValue, exitTimePickerValue, openTimePicker, getMinTime, onContinueTimePicker, onCloseTimePicker, disabledTimePickerWorplace, renderTimePickerMessageWorkplace])

  const getSlideForm = useCallback(() => {
    if (selectedType === DESK_RESERVATION) return slide1Workplace()
    if (selectedType === ROOM_RESERVATION) return slide1Room()
  }, [selectedType, slide1Room, slide1Workplace])

  const detailListParking = useCallback((item) => {
    return (
      <ul className="detail-list">
        <li>{t('parking')}</li>
        <li><EventIcon sx={iconSx} /><span>{formatDateMedMed(item.parking_date)}</span></li>
        <li><Place sx={iconSx} /><span>{`${item.parking_space_label}, ${item?.parking_lot?.name}`}</span></li>
        {askForParkingReservationVehicle &&
          <li><DirectionsCar sx={iconSx} width={10} /><span>{item?.vehicle?.license_plate}</span></li>}
        {askForParkingReservationEntryTime &&
          <li><WatchLaterIcon sx={iconSx} /><span>{formatTime(item.entry_time)}</span></li>}
        {askForParkingReservationReason &&
          <li><Notes sx={iconSx} /><span>{reasonI18n(item.reason)}</span></li>}
      </ul>
    )
  }, [t, askForParkingReservationVehicle, askForParkingReservationEntryTime, askForParkingReservationReason])

  const detailListWorkplace = useCallback((item) => {
    return (
      <ul className="detail-list">
        <li>{workplaceCustomLabel ? workplaceCustomLabel : t('workplace')}</li>
        <li><EventIcon sx={iconSx} /><span>{formatDateISOShort(item.reservation_date)}</span></li>
        <li><LocationCityIcon sx={iconSx} /><span>{getPlaceByID(item)}</span></li>
        <li>
          {enabledWorkplaceCustomIcon && workplaceCustomIconIndex ?
            <CustomWorkplaceIcons
              index={workplaceCustomIconIndex}
              sx={iconSx}
              color={"#8C8CA1"}
            />
            :
            <AirlineSeatReclineNormalIcon sx={iconSx} />
          }
          <span>{item?.desk?.name}</span>
        </li>
        {askForDeskReservationEntryTime &&
          <li><WatchLaterIcon sx={iconSx} /><span>{getTime(item, t)}</span></li>}
      </ul>
    )
  }, [askForDeskReservationEntryTime, getPlaceByID, t, workplaceCustomLabel, enabledWorkplaceCustomIcon, workplaceCustomIconIndex])

  const detailListRoom = useCallback((item) => {
    return (
      <ul className="detail-list">
        <li>{t('room')}</li>
        <li><EventIcon sx={iconSx} /><span>{formatDateISOShort(item.reservation_date)}</span></li>
        <li><LocationCityIcon sx={iconSx} /><span>{getLocalitation(room)}</span></li>
        <li><GroupsIcon sx={iconSx} /><span>{room?.name}</span></li>
        <li><WatchLaterIcon sx={iconSx} /><span>{getTime(item, t)}</span></li>
        {enabledWorkplaceRoomReservationsAttendees && item.attendees?.length > 0 &&
          <li>
            <div className='attendees'>
              <AlternateEmail sx={iconSx} />
              <div className='attendees-list'>
                <span>{t('Attendees')}:</span>
                <ul>
                  {item.attendees.map((attendee, index) => (
                    <li key={index}>{attendee}</li>
                  ))}
                </ul>
              </div>
            </div>
          </li>
        }
        {enabledWorkplaceRoomReservationsAttendees && item.meeting_url &&
          <li>
            <div className='attendees'>
              <LinkIcon sx={iconSx} />
              <div className='attendees-list'>
                <ul>
                  <li>{item.meeting_url}</li>
                </ul>
              </div>
            </div>
          </li>
        }
      </ul>
    )
  }, [t, enabledWorkplaceRoomReservationsAttendees, room])

  const slideSuccess = useCallback(() => {
    return (
      <SuccessBox>
        {successItems.map((item, index) => (
          <div key={index} className="detail-list-wrapper">
            {item.type === 'parking' && detailListParking(item)}
            {item.type === 'desk' && detailListWorkplace(item)}
            {item.type === 'room' && detailListRoom(item)}
          </div>
        ))}
      </SuccessBox>
    )
  }, [detailListParking, detailListWorkplace, detailListRoom, successItems])

  const onConfirmMarker = useCallback(() => {
    setDesk(selectedMarker)
    setOpenMap(false)
  }, [selectedMarker])

  const onCancelMarker = useCallback(() => {
    const _markers = [...markers]

    _markers.forEach(m => {
      if (m.id === desk.id) {
        m.selected = true
      } else m.selected = false
    })

    setOpenMap(false)
    setMarkers(_markers)
    setSelectedMarker(desk)
  }, [desk, markers])

  const onCancelRoom = useCallback(() => {
    setOpenRoomsList(false)
  }, [])

  const onContinueRoom = useCallback(params => {
    const { entryTime, exitTime, allTime = false, selectedRoom } = params
    setAllTime(allTime)
    setEntryTime(entryTime)
    setExitTime(exitTime)
    setRoom(selectedRoom)
    setOpenRoomsList(false)
  }, [])

  const onClickMarker = useCallback((e, marker) => {
    const _markers = [...markers]
    if (marker.occupied) return

    _markers.forEach(m => {
      if (m.id === marker.id)
        m.selected = true
      else m.selected = false
    })

    setMarkers(_markers)
    setSelectedMarker(marker)

  }, [markers])

  const getLegend = useCallback(() => {
    const siteName = sites.find(s => s.id === site)?.name
    const buildingName = buildings.find(b => b.id === building)?.name
    const floorName = floors.find(f => f.id === floor)?.name
    return (
      <Stack
        direction='row'
        columnGap={8}
        alignItems='center'
        className="legend"
      >
        <Stack
          justifyContent={isLarge ? 'flex-start' : 'space-evenly'}
          direction="row"
          columnGap={4}
          width={isLarge ? 'auto' : '100%'}
        >
          <Stack direction='row' alignItems='center' columnGap={1}><DeskDot width="12" color="#75D385" /> {t('available')}</Stack>
          <Stack direction='row' alignItems='center' columnGap={1}><DeskDot width="12" color="#CF706F" /> {t('busy')}</Stack>
          <Stack direction='row' alignItems='center' columnGap={1}><CheckCircleIcon sx={iconSelected} /> {t('selected')}</Stack>
        </Stack>
        {isLarge &&
          <Stack direction='row' alignItems='center'>
            <LocationCircle /> <strong>{`${siteName}, ${buildingName}, ${floorName}`}</strong>
          </Stack>
        }
      </Stack>
    )
  }, [sites, buildings, floors, isLarge, t, site, building, floor])


  const getAmenities = useCallback(() => {
    if (selectedMarker.amenities && selectedMarker.amenities.length) return (
      <div className='amenities'>
        <h3>{t('amenities')}:</h3>
        {selectedMarker?.amenities.map(a => getDeskAmenities(a, t))}
      </div>
    )
  }, [selectedMarker, t])

  const detailMap = useCallback(() => {
    return (
      <Dialog
        className="dialog-detail detail-map"
        fullScreen={!isLarge}
        fullWidth={true}
        maxWidth="lg"
        open={openMap}
        onClose={onCancelMarker}
        aria-labelledby="responsive-dialog-title">
        <DialogContent className="dialog-detail-content">
          {isLarge ?
            getLegend() :
            <div className="detail-map-header">
              <h1>{t('selected desk')}</h1>
              <h2>{selectedMarker.name}</h2>
              {!isLarge && getLegend()}
              <IconButton
                className="close-button"
                aria-label="close"
                onClick={onCancelMarker} >
                <CloseIcon />
              </IconButton>
            </div>
          }
          <FloorMap
            floorImage={floorImage}
            markers={markers}
            onClickMarker={onClickMarker}
          />
          <div className='detail-map-footer-info'>
            {
              isLarge &&
              selectedMarker.id !== -1 &&
              <Stack
                className='selected-marker-title'
                direction='row'
                columnGap={1}
                alignItems='baseline'
                color='#1D8099'
              >
                <InfoIcon fontSize='xs' />
                <h2>{t('Selected Space')}: {selectedMarker.name}</h2>
              </Stack>
            }
          </div>
          <Stack
            direction={isLarge ? 'row' : 'column'}
            justifyContent="center"
            alignItems="flex-start"
            spacing={4}
          >
            <div className='detail-map-footer-info'>
              {getAmenities()}
            </div>
            <Button
              fullWidth
              variant="contained"
              disabled={selectedMarker.id === -1}
              onClick={onConfirmMarker} >
              {t('confirm')}
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    )
  }, [floorImage, getAmenities, getLegend, isLarge, markers, onCancelMarker, onClickMarker, onConfirmMarker, openMap, selectedMarker, t])

  const roomsModal = useCallback(() => {
    return (
      <RoomsModal
        onContinueRoom={onContinueRoom}
        onCancelRoom={onCancelRoom}
        setEntryTime={setEntryTime}
        setExitTime={setExitTime}
        defaultEntryTime={unformattedDefaultEntryTime}
        defaultExitTime={unformattedDefaultExitTime}
        workplaceDefaultEntryTime={workplaceDefaultEntryTime}
        workplaceDefaultExitTime={workplaceDefaultExitTime}
        openRoomsList={openRoomsList}
        defaultRoom={DEFAULT_ROOM}
        isLoading={loading}
        isLarge={isLarge}
        rooms={rooms}
        date={date}
        availableAmenities={availableAmenities}
      />
    )
  }, [onContinueRoom, onCancelRoom, unformattedDefaultEntryTime, unformattedDefaultExitTime, workplaceDefaultEntryTime, workplaceDefaultExitTime, openRoomsList, DEFAULT_ROOM, loading, isLarge, rooms, date, availableAmenities])

  const doYouWantToEditModal = useCallback(() => {
    return (
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={doYouWantToEdit}
        onClose={() => setDoYouWantToEdit(false)}
        aria-labelledby="responsive-dialog-title">
        <DialogContent className="dialog-info-content">
          <div className="detail-header">
            <InfoOutlinedIcon />
            {t('do you want to edit this field?')}
          </div>
          <Divider className="detail-hr" />
          <p className='enabled'>{t('editing this field will require you to select all subsequent details again')}</p>
          <Stack spacing={2} direction="row" className='detail-footer'>
            <Button
              variant="outlined"
              onClick={() => setDoYouWantToEdit(false)} >
              {t('go back')}
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    )
  }, [doYouWantToEdit, t])

  const getSlide = useCallback(() => {
    if (activeStep === FORM) return getSlideForm()
    return slideSuccess()
  }, [activeStep, getSlideForm, slideSuccess])

  const maximumOccupancyFloorModal = useCallback(() => {
    return (
      <Dialog
        className='image-information-dialog'
        fullWidth={true}
        maxWidth="sm"
        open={maximumOccupancyFloor}
        onClose={() => setMaximumOccupancyFloor(false)}
        aria-labelledby="responsive-dialog-title">
        <DialogContent className="dialog-info-content">
          <img src="calendar_reservation.svg" alt="calendar reservation" />
          <div className="detail-header">{t('sorry')}</div>
          <p className='enabled'>{t('all spaces are occupied on this floor for the selected date and time')}</p>
          <Stack spacing={2} direction="column" className='detail-footer'>
            <Button
              startIcon={<EventIcon />}
              variant="outlined"
            >
              {t('change date')}
            </Button>
            <Button
              startIcon={<AccessTimeIcon />}
              variant="outlined"
            >
              {t('change schedule')}
            </Button>
            <Button
              startIcon={<PlaceOutlinedIcon />}
              variant="outlined"
            >
              {t('change place')}
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    )
  }, [maximumOccupancyFloor, t])


  return (
    <div className='WorkplaceReservation'>
      {getSlide()}
      {detailMap()}
      {roomsModal()}
      {doYouWantToEditModal()}
      {maximumOccupancyFloorModal()}
    </div>
  )
}

const mapStateToProps = state => {
  return {
    sites: state.workplace.sites,
    minReservationTime: state.profile.company.min_reservation_time,
    workplaceDefaultEntryTime: getWorkplaceDefaultEntryTime(state),
    workplaceDefaultExitTime: getWorkplaceDefaultExitTime(state),
    unformattedDefaultEntryTime: state.profile.company.workplace_default_entry_time || EIGHT_AM,
    unformattedDefaultExitTime: state.profile.company.workplace_default_exit_time || FIVE_PM,
    askForRoomReservationNotes: state.profile.company.ask_for_room_reservation_notes,
    askForRoomReservationReason: state.profile.company.ask_for_room_reservation_reason,
    askForDeskReservationReason: state.profile.company.ask_for_desk_reservation_reason,
    askForDeskReservationEntryTime: state.profile.company.ask_for_desk_reservation_entry_time,
    loading: state.backdrop.loading,
    workplaceCustomLabel: state.profile.company.workplace_custom_label,
    workplaceCustomIconIndex: state.profile.company.workplace_custom_icon_index,
    enabledWorkplaceCustomIcon: state.profile.company.enabled_workplace_custom_icon,
    enabledWorkplaceRoomReservationsAttendees: state.profile.company.enabled_workplace_room_reservations_attendees,
    parkingReservationData: state.parking.pendingReservation,
    askForParkingReservationReason: state.profile.company.ask_for_parking_reservation_reason,
    askForParkingReservationVehicle: state.profile.company.ask_for_parking_reservation_vehicle,
    askForParkingReservationEntryTime: state.profile.company.ask_for_parking_reservation_entry_time,
    enabledPD: ENABLED_PD.includes(state.profile.domain),
    enabledPR: ENABLED_PR.includes(state.profile.domain),
    reservationsAndAssignations: state.general.reservationsAndAssignations,
  }
}

const mapDispatchToProps = {
  dispatch,
  setLoading,
  defaultSuccess,
  defaultCatch
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkplaceReservation)